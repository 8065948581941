import { request } from "./utils";
import { IFailedRequest, IToken } from "./login.services";
import {
  IUserDetails,
  ICommunities,
  ICommunityGroup,
  IPhoneNumber,
  IResidentPhoneNumber,
} from "./header.services";
import { IResident, IUnit } from "./dashboard.services";
import { getClientID } from "../features/login/loginSlice";

export const settingsServices = {
  updateMyProfile,
  updateCommunityInfo,
  updateRole,
  updateUser,
  addCommunityUser,
  deleteCommunityUser,
  updateNotificationGroup,
  sendHandOff,
  sendHandOffSettings,
  handoffScheduleToggle,
  getHandoffSettings,
  addNotificationGroup,
  deleteNotificationGroup,
  getCommunityEventTypes,
  getCommunityEventConfigs,
  getEventConfig,
  enableCommunityEventConfig,
  updateCommunityEventConfig,
  getUnitConfigByEventType,
  createUnit,
  addResident,
  addPhoneNumber,
  enableUnitEventConfig,
  updateUnitConfigByEventType,
  updateResident,
  updateResidentPhoneNumber,
  deleteResident,
  deleteResidentPhoneNumber,
  updateCommunityUnit,
  deleteCommunityUnit,
  getUnitGroupsByEventType,
  updateUnitGroupsConfig,
  updateCommunityGroupsConfig,
  togglePauseUnit,
  setUnitPauseExpiration,
  setUnitSecurityNotification,
  resendInvitation,
  changePassword,
};
export interface IOrganizationRole {
  email: string;
  name: string;
  organization_id: string;
  time_created: string;
  user_id: string;
}

export interface ICommunityRole {
  id: string;
  all_units: boolean;
  community_id: string;
  community_type: string;
  email: string;
  name: string;
  time_created: string;
  user_id: string;
}

export interface IProfileData {
  first_name?: string;
  last_name?: string;
  country?: string;
  language?: string;
  time_zone?: string;
  temperature_unit?: string;
  // if is 24h hour option,
  is_twenty_four_time?: string;
}

export interface ICommunityInfoData {
  id?: string;
  name: string;
  postal_code: string;
  country?: string;
  time_zone?: string;
  language?: string;
}
export interface IUpdateRoleData {
  email: string;
  communityID: string;
  roleData: {
    unit_ids?: string[];
    all_units?: boolean;
    name: string;
  };
}

export interface IUpdateCommunityUserData {
  userID: string;
  userData: {
    group_ids: string[];
    community_id: string;
  };
}
export interface IAddCommunityUserData {
  name: string;
  email: string;
  community_id: string;
  unit_ids?: string[];
  all_units?: boolean;
  group_ids: string[];
}

export interface IDeleteUserData {
  userEmail: string;
  community_id: string;
}

export interface IResendInviteData {
  email: string;
  community_id: string;
}

export interface IUpdateNotificationGroupData {
  group_id: string;
  groupData: {
    name?: string | null;
    user_ids?: string[];
  };
}
export interface IAddNotificationGroupData {
  community_id: string;
  name?: string | null;
  user_ids?: string[];
}

export interface IPasswordChangeData {
  email: string;
  oldPassword: string;
  newPassword: string;
}

export interface IHandoffMessageData {
  message?: string;
  community_id?: string;
}

export interface IScheduleEnabled {
  community_id?: string;
  is_enabled: boolean;
}

export interface IHandoffSettings {
  community_id: string | null;
  is_enabled: boolean | null;
  time_created: string | null;
  configuration: string | null;
}

export interface IHandoffSettingsData {
  community_id?: string;
  is_enabled: boolean | null | undefined;
  configuration: {
    message?: string;
    0: {
      is_enabled: boolean | null;
      start_time: string | null;
    } | null;
    1: {
      is_enabled: boolean | null;
      start_time: string | null;
    } | null;
    2: {
      is_enabled: boolean | null;
      start_time: string | null;
    } | null;
    3: {
      is_enabled: boolean | null;
      start_time: string | null;
    } | null;
    4: {
      is_enabled: boolean | null;
      start_time: string | null;
    } | null;
    5: {
      is_enabled: boolean | null;
      start_time: string | null;
    } | null;
    6: {
      is_enabled: boolean | null;
      start_time: string | null;
    } | null;
  };
}

export interface EventType {
  community_types: string[] | null;
  description: string | null;
  id: string;
  info: string | null;
  name: string | null;
  notification_type: string | null;
  position: number | null;
  roles: string[] | null;
  type: string;
  category?: string | null;
}
export interface EventTypeConfig {
  community_id: string | null;
  configuration: string | null;
  event_type: string | null;
  event_type_id: string | null;
  id: string;
  is_enabled: boolean | null;
  is_zone_event: boolean | null;
  position: number | null;
  send_notifications_only: null;
  time_created: string | null;
  time_updated: string | null;
  type: string | null;
  notification_type?: string;
}

export interface UnitEventTypeConfig extends EventTypeConfig {
  event_config_id: string;
  unit_id: string;
}

interface EventTypeConfigDetails {
  max_threshold?: number;
  min_threshold?: number;
  duration?: number;
  is_enabled: boolean;
  local_time_start?: string;
  local_time_end?: string;
  all_day?: boolean;
  count?: number | null;
  notification_groups?: string[];
}
export interface EventTypeLevelConfig {
  min_creation_window?: number;
  is_enabled?: boolean;
  "1"?: EventTypeConfigDetails;
  "2"?: EventTypeConfigDetails;
  "3"?: EventTypeConfigDetails;
  "4"?: EventTypeConfigDetails;
}

export interface ICommunityConfigData {
  eventConfigID: string;
  is_enabled?: boolean;
  configuration?: {
    level?: string;
    is_enabled?: boolean;
    duration?: number;
    count?: number;
    all_day?: boolean;
    local_time_start?: string | null;
    local_time_end?: string | null;
  };
}
export interface IUnitConfigData {
  communityID: string;
  eventType: string;
}

export interface IUnitEventConfigData {
  eventType: string;
  unitIDs: string[];
  is_enabled?: boolean;
  configuration?: {
    level?: string;
    is_enabled?: boolean;
    duration?: number;
    count?: number;
    all_day?: boolean;
    local_time_start?: string | null;
    local_time_end?: string | null;
  };
}

export type IAddResident = Omit<IResident, "id">;
interface IAddResidentData {
  communityID: string;
  resident: IAddResident;
}

export interface IUpdateResident {
  community_id: string;
  resident: IResident;
}
export interface IDeleteResident {
  resident_id: string;
  community_id: string;
}
export interface IUpdateResidentNumber {
  resident_id: string;
  phoneNumber: IPhoneNumber;
}
export interface IDeleteResidentNumber {
  resident_id: string;
  phoneNumberID: string;
}

export type IAddPhoneNumberData = Omit<IPhoneNumber, "id">;
export type IAddContactPhoneNumber = Omit<IResidentPhoneNumber, "id">;

interface IEventTypeGroups {
  "1": string[];
  "2": string[];
  "3": string[];
  "4": string[];
  event_type: string;
  notification_type: string;
}

export interface IUnitGroupConfigs {
  hard_notifications: IEventTypeGroups[];
  soft_notifications: IEventTypeGroups[];
  unit_id: string;
  unit_name?: string | null;
  unit_number?: string | null;
}

export interface IUnitGroupConfig {
  unit_ids: string[];
  event_types: string[] | "all";
  level_1: string[];
  level_2: string[];
  level_3: string[];
  level_4: string[];
}

export interface ICommunityGroupConfig {
  communityID: string;
  requestData: {
    level_1: string[];
    level_2: string[];
    level_3: string[];
    level_4: string[];
  };
}
export interface IPauseUnitData {
  unitID: string;
  pauseValue: boolean;
  resetExpiration: boolean;
}

export interface IExpirationUnitData {
  unitID: string;
  pauseNotificationsExpiration: string | null;
}
export interface ISecurityNotificationPauseData {
  unitID: string;
  securityNotificationEnabled: boolean | null;
}

/**
 * Updates user profile
 * @param {IProfileData} profileData profile data to update
 * @returns {IUserDetails} Indicates the succesful completion of the API call
 */

async function updateMyProfile(
  profileData: IProfileData
): Promise<IUserDetails> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      ...profileData,
    }),
  };

  const data = await request(`/users`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 * Updates community info
 * @param {ICommunityInfoData} infoData profile data to update
 * @returns {ICommunities} Indicates the succesful completion of the API call
 */

async function updateCommunityInfo(
  infoData: ICommunityInfoData
): Promise<ICommunities> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      ...infoData,
    }),
  };

  const data = await request(`/communities/${infoData.id}`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

interface IUpdatedUser {
  all_units: boolean;
  name: string;
  unit_ids: string[];
  user_id: string;
}

/**
 *
 * @param {IUpdateRoleData} infoData User data to update
 * @returns {IUpdatedUser}
 */

async function updateRole(infoData: IUpdateRoleData): Promise<IUpdatedUser> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      ...infoData.roleData,
    }),
  };

  const data = await request(
    `/roles?email=${encodeURIComponent(infoData.email)}&community_id=${
      infoData.communityID
    }`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

async function sendHandOff(handoffData: IHandoffMessageData): Promise<null> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      ...handoffData,
    }),
  };

  const data = await request(
    `/communities/${handoffData.community_id}/handoff_notification`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 *
 * @param {IUpdateCommunityUserData} infoData Role data to update
 * @returns {IUserDetails}
 */

async function updateUser(
  infoData: IUpdateCommunityUserData
): Promise<IUserDetails> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      ...infoData.userData,
    }),
  };

  const data = await request(
    `/users/${infoData.userID}/groups`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 *
 * @param {IAddCommunityUserData} infoData Role data to update
 * @returns {IUserDetails}
 */

async function addCommunityUser(
  infoData: IAddCommunityUserData
): Promise<IUserDetails> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      ...infoData,
    }),
  };

  const data = await request(`/roles`, requestOptions);
  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 *
 * @param {IDeleteUserData} data Email of the user to delete and community id
 * @returns {boolean}
 */

async function deleteCommunityUser(
  deleteData: IDeleteUserData
): Promise<boolean> {
  const requestOptions: RequestInit = {
    method: "DELETE",
  };

  const data = await request(
    `/roles?email=${encodeURIComponent(deleteData.userEmail)}&community_id=${
      deleteData.community_id
    }`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 *
 * @param {IUpdateNotificationGroupData} infoData Notification group data to update
 * @returns {ICommunityGroup}
 */

async function updateNotificationGroup(
  infoData: IUpdateNotificationGroupData
): Promise<ICommunityGroup> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      ...infoData.groupData,
    }),
  };

  const data = await request(`/groups/${infoData.group_id}`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 *
 * @param {IAddNotificationGroupData} infoData Notification group data to add
 * @returns {ICommunityGroup}
 */

async function addNotificationGroup(
  infoData: IAddNotificationGroupData
): Promise<ICommunityGroup> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      ...infoData,
    }),
  };

  const data = await request(`/groups`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 *
 * @param {string} groupID Notification group ID to delete
 * @returns {boolean}
 */

async function deleteNotificationGroup(groupID: string): Promise<boolean> {
  const requestOptions: RequestInit = {
    method: "DELETE",
  };

  const data = await request(`/groups/${groupID}`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 *
 * @param {IScheduleEnabled} handoffScheduleData Data to toggle scheduled handoff
 * @returns {IHandoffSettingsData}
 */

async function handoffScheduleToggle(
  handoffScheduleData: IScheduleEnabled
): Promise<IHandoffSettingsData> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      ...handoffScheduleData,
    }),
  };
  const data = await request(
    `/communities/${handoffScheduleData.community_id}/handoff_settings`,
    requestOptions
  );
  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 *
 * @param {IHandoffSettingsData} handoffScheduleData Data for handoff configuration
 * @returns {IHandoffSettings}
 */

async function sendHandOffSettings(
  handoffScheduleData: IHandoffSettingsData
): Promise<IHandoffSettings> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      ...handoffScheduleData,
    }),
  };
  const data = await request(
    `/communities/${handoffScheduleData.community_id}/handoff_settings`,
    requestOptions
  );
  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 *
 * @param {string} community_id Community ID to get handoff settings
 * @returns {IHandoffSettings}
 */

async function getHandoffSettings(
  community_id: string
): Promise<IHandoffSettings> {
  const requestOptions: RequestInit = {
    method: "GET",
    body: null,
  };

  const data = await request(
    `/communities/${community_id}/handoff_settings`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 *
 * @param {string} community_id
 * @returns {EventType}
 */

async function getCommunityEventTypes(
  community_id: string
): Promise<EventType[]> {
  const requestOptions: RequestInit = {
    method: "GET",
    body: null,
  };

  const data = await request(
    `/event_types?community_id=${community_id}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 * @param {string} community_id
 * @returns {EventTypeConfig[]}
 */

async function getCommunityEventConfigs(
  community_id: string
): Promise<EventTypeConfig[]> {
  const requestOptions: RequestInit = {
    method: "GET",
    body: null,
  };

  const data = await request(
    `/event_configs?community_id=${community_id}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 * @param {string} event_config_id
 * @returns {EventTypeConfig}
 */

async function getEventConfig(
  event_config_id: string
): Promise<EventTypeConfig> {
  const requestOptions: RequestInit = {
    method: "GET",
    body: null,
  };

  const data = await request(
    `/event_configs/${event_config_id}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 * @param {ICommunityConfigData} configData
 * @returns {EventTypeConfig}
 */

async function enableCommunityEventConfig(
  configData: ICommunityConfigData
): Promise<EventTypeConfig> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      is_enabled: configData.is_enabled,
    }),
  };

  const data = await request(
    `/event_configs/${configData.eventConfigID}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 * @param {ICommunityConfigData} configData
 * @returns {EventTypeLevelConfig}
 */

async function updateCommunityEventConfig(
  configData: ICommunityConfigData
): Promise<EventTypeLevelConfig> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      ...configData.configuration,
    }),
  };

  const data = await request(
    `/event_configs/${configData.eventConfigID}?only_config=1`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 * @param {IUnitConfigData} requestData
 * @returns {UnitEventTypeConfig[]}
 */

async function getUnitConfigByEventType(
  requestData: IUnitConfigData
): Promise<UnitEventTypeConfig[]> {
  const requestOptions: RequestInit = {
    method: "GET",
  };

  const data = await request(
    `/unit_event_configs?community_id=${requestData.communityID}&event_type=${requestData.eventType}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 * @param {Partial<IUnit>} requestData
 * @returns {IUnit}
 */

async function createUnit(requestdata: Partial<IUnit>): Promise<IUnit> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      ...requestdata,
    }),
  };

  const data = await request(`/units`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

async function updateCommunityUnit(unit: Partial<IUnit>): Promise<IUnit> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      ...unit,
    }),
  };

  const data = await request(`/units/${unit.id}`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

async function deleteCommunityUnit(unitID: string): Promise<boolean> {
  const requestOptions: RequestInit = {
    method: "DELETE",
  };

  const data = await request(`/units/${unitID}`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 * @param {IAddResidentData} requestData
 * @returns {IResident}
 */

async function addResident(requestData: IAddResidentData): Promise<IResident> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      ...requestData.resident,
    }),
  };

  const data = await request(
    `/communities/${requestData.communityID}/residents`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 * @param {IAddPhoneNumberData} requestData
 * @returns {boolean}
 */

async function addPhoneNumber(
  requestData: IAddPhoneNumberData
): Promise<IPhoneNumber> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      ...requestData,
    }),
  };

  const data = await request(`/phone_number`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 * @param {IUnitEventConfigData} requestData
 * @returns {UnitEventTypeConfig[]}
 */

async function enableUnitEventConfig(
  requestData: IUnitEventConfigData
): Promise<UnitEventTypeConfig[]> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      is_enabled: requestData.is_enabled,
      unit_ids: requestData.unitIDs,
    }),
  };

  const data = await request(
    `/unit_event_configs?event_type=${requestData.eventType}&multiple_units=1`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 * @param {IUnitEventConfigData} requestData
 * @returns {UnitEventTypeConfig[]}
 */

async function updateUnitConfigByEventType(
  requestData: IUnitEventConfigData
): Promise<UnitEventTypeConfig[]> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      ...requestData.configuration,
      unit_ids: requestData.unitIDs,
    }),
  };

  const data = await request(
    `/unit_event_configs/configuration?event_type=${requestData.eventType}&multiple_units=1`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

async function updateResident(
  requestData: IUpdateResident
): Promise<IResident> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      ...requestData.resident,
    }),
  };

  const data = await request(
    `/communities/${requestData.community_id}/residents/${requestData.resident.id}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

async function deleteResident(requestData: IDeleteResident): Promise<boolean> {
  const requestOptions: RequestInit = {
    method: "DELETE",
  };

  const data = await request(
    `/communities/${requestData.community_id}/residents/${requestData.resident_id}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

export interface IUpdateResidentNumber {
  resident_id: string;
  phoneNumber: IPhoneNumber;
}

async function updateResidentPhoneNumber(
  requestData: IUpdateResidentNumber
): Promise<IResidentPhoneNumber> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      ...requestData.phoneNumber,
    }),
  };

  const data = await request(
    `/phone_number/${requestData.phoneNumber.id}?user_type=resident&resident_id=${requestData.resident_id}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}
async function deleteResidentPhoneNumber(
  requestData: IDeleteResidentNumber
): Promise<boolean> {
  const requestOptions: RequestInit = {
    method: "DELETE",
  };

  const data = await request(
    `/phone_number/${requestData.phoneNumberID}?user_type=resident&resident_id=${requestData.resident_id}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

async function getUnitGroupsByEventType(
  community_id: string
): Promise<IUnitGroupConfigs[]> {
  const requestOptions: RequestInit = {
    method: "GET",
  };

  const data = await request(
    `/communities/${community_id}/unit_notification_groups`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

async function updateUnitGroupsConfig(
  unitGroupConfigData: IUnitGroupConfig
): Promise<boolean> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      ...unitGroupConfigData,
    }),
  };

  const data = await request(
    `/unit_event_configs/notification_groups`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

async function updateCommunityGroupsConfig(
  groupConfigData: ICommunityGroupConfig
): Promise<EventTypeConfig[]> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      ...groupConfigData.requestData,
    }),
  };

  const data = await request(
    `/communities/${groupConfigData.communityID}/notification_groups`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

async function togglePauseUnit(pauseUnitData: IPauseUnitData): Promise<IUnit> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      is_notifications_paused: pauseUnitData.pauseValue,
      ...(pauseUnitData.resetExpiration && {
        pause_notifications_expiration: null,
      }),
    }),
  };

  const data = await request(`/units/${pauseUnitData.unitID}`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

async function setUnitPauseExpiration(
  expirationUnitData: IExpirationUnitData
): Promise<IUnit> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      pause_notifications_expiration:
        expirationUnitData.pauseNotificationsExpiration,
    }),
  };

  const data = await request(
    `/units/${expirationUnitData.unitID}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

async function setUnitSecurityNotification(
  securityNotificationData: ISecurityNotificationPauseData
): Promise<IUnit> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      security_notification_enabled:
        securityNotificationData.securityNotificationEnabled,
    }),
  };

  const data = await request(
    `/units/${securityNotificationData.unitID}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 *
 * @param {IResendInviteData} data Email of the user to resend invite to and community id
 * @returns {boolean}
 */

async function resendInvitation(userData: IResendInviteData): Promise<boolean> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      ...userData,
    }),
  };

  const data = await request(`/register/resend_invitation`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 * @param {IPasswordChangeData} passwordResetData contains the needed information to reset password
 * @returns {IToken} A json containing the updated token
 */
async function changePassword(
  passwordChangeData: IPasswordChangeData
): Promise<IToken | IFailedRequest> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      email: passwordChangeData.email,
      new_password: passwordChangeData.newPassword,
      old_password: passwordChangeData.oldPassword,
      client_id: getClientID(),
    }),
  };

  const data = await request("/login/password/change", requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}
