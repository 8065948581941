import {
  Button,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Popover,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormRow } from "./common/FormRow";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  DefaultRegionCode,
  NAME_CHARACTER_LIMIT,
  PhoneTypes,
} from "../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import { PhoneNumberPicker, UnitPhoneNumber } from "./common/PhoneNumberPicker";
import { GenericPicker } from "./common/GenericPicker";
import {
  createPhoneNumber,
  deletePhoneNumber,
  deleteResident,
  IAddPhoneNumber,
  updatePhoneNumber,
  updateResident,
} from "./settingsThunks";
import { getRegionCodeForCountryCode } from "awesome-phonenumber";
import { getStatesList } from "./common/helpers";
import { IResident } from "../../services/dashboard.services";
import {
  IDeleteResident,
  IUpdateResident,
} from "../../services/settings.services";
import { IPhoneNumber } from "../../services/header.services";
import {
  adjustPhoneNumber,
  getPhoneOperationsArr,
  phoneOperations,
} from "./common/phoneHelpers";
import { ErrorCodes } from "../../services/constants";
import { handledError } from "../app/appSlice";
import { DateOfBirthDatePicker } from "./DateOfBirthDatePicker";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ConfirmDialog, DialogTypes } from "../common/ConfirmDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editResidentSettings: {
      padding: theme.spacing(6, 7),
      width: "550px",
      overflowY: "auto",
    },
    sectionTitle: {
      position: "relative",
      marginTop: 0,
      marginBottom: theme.spacing(4),
      textAlign: "center",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    fieldList: {
      listStyle: "none",
      padding: 0,
      width: "450px",
    },
    inputWidth: {
      width: "100%",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    buttonRoot: {
      fontFamily: theme.typography.secondaryFontFamily,
      fontSize: "1.1rem !important",
      minWidth: "120px",
    },
    phoneNumbersContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    phoneNumbersTitle: {
      marginTop: theme.spacing(7),
      marginLeft: theme.spacing(8),
    },
    formRow: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: ".5em",
    },
    helperText: {
      minHeight: "20px",
      lineHeight: "1.2",
      color: theme.palette.error.main,
    },
    menu: {
      display: "flex",
      justifyContent: "flex-end",
    },
    menuButton: {
      padding: 0,
    },
    menuItem: {
      paddingTop: 10,
      paddingBottom: 10,
    },
    menuList: {
      paddingTop: 16,
      paddingBottom: 16,
    },
  })
);

const DropdownInput = (props: {
  id: string;
  value: string | undefined;
  disabled?: boolean;
  handleClick: (event: React.MouseEvent<HTMLDivElement>, id: string) => void;
}) => {
  const { id, value, disabled, handleClick } = props;
  const classes = useStyles();
  return (
    <Input
      id={id}
      type="text"
      value={value ?? ""}
      disabled={disabled}
      onClick={!disabled ? (e) => handleClick(e, id) : undefined}
      className={classes.inputWidth}
      endAdornment={
        !disabled ? (
          <InputAdornment id="role" position="end">
            <IconButton aria-label={`toggle ${id} picker`}>
              <ArrowDropDownIcon />
            </IconButton>
          </InputAdornment>
        ) : undefined
      }
    />
  );
};

enum FormFieldIDs {
  firstName = "first-name",
  lastName = "last-name",
  addressOne = "address-one",
  addressTwo = "address-two",
  city = "city",
  state = "state",
  zipCode = "zip-code",
  phoneNumberOne = "phone-number-one",
  phoneNumberTwo = "phone-number-two",
  dateOfBirth = "date-of-birth",
}

type UnitPhoneNumberUpdate = UnitPhoneNumber & { id?: string | null };

type FormState = {
  [FormFieldIDs.firstName]?: string | null;
  [FormFieldIDs.lastName]?: string | null;
  [FormFieldIDs.addressOne]?: string | null;
  [FormFieldIDs.addressTwo]?: string | null;
  [FormFieldIDs.city]?: string | null;
  [FormFieldIDs.state]?: string | null;
  [FormFieldIDs.zipCode]?: string | null;
  [FormFieldIDs.phoneNumberOne]: UnitPhoneNumberUpdate;
  [FormFieldIDs.phoneNumberTwo]: UnitPhoneNumberUpdate;
  [FormFieldIDs.dateOfBirth]?: string | null;
};

const getNewPhoneNumber = (
  phone: UnitPhoneNumberUpdate,
  resident_id: string
): IAddPhoneNumber["phoneNumber"] => {
  delete phone.id;
  return {
    ...adjustPhoneNumber(phone, "resident"),
    resident_id: resident_id,
  };
};
const getUpdatedPhoneNumber = (
  phone: UnitPhoneNumberUpdate,
  oldPhoneNumbers: IPhoneNumber[] | null | undefined
): IPhoneNumber => {
  const oldPhone = oldPhoneNumbers?.find(
    (oldPhone) => phone.id === oldPhone.id
  );
  if (oldPhone) {
    const updatedPhoneNumber: { [key: string]: any } = {
      ...oldPhone,
      ...adjustPhoneNumber(phone, "resident"),
      id: phone.id as string,
    };

    Object.keys(oldPhone).forEach((key) => {
      if (updatedPhoneNumber[key] === null) {
        delete updatedPhoneNumber[key];
      }
    });
    return updatedPhoneNumber as IPhoneNumber;
  } else {
    return {
      ...adjustPhoneNumber(phone, "resident"),
      id: phone.id as string,
    };
  }
};

export const EditResidentSettings = (props: IProps) => {
  const { resident, homeCareCommunity } = props;
  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* Selectors */
  const communityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );
  const communityCountry = useAppSelector(
    (state) => state.headerState.selectedCommunity?.country
  );

  const defaultRegionCode = useAppSelector(
    (state) => state.headerState.user?.country ?? DefaultRegionCode
  );
  const unit = useAppSelector((state) =>
    state.headerState.units?.find((unit) => unit.id === resident.unit_id)
  );

  const errorCode = useAppSelector((state) => state.settingsState.errorCode);

  /* ---- Effects ---- */
  useEffect(() => {
    if (ErrorCodes.hasOwnProperty(errorCode)) {
      switch (ErrorCodes[errorCode]) {
        case ErrorCodes.NAME_CONFLICT:
          dispatch(handledError());
          setFormError((prevVal) => ({
            ...prevVal,
            name_conflict: t("resident_city_name_conflict"),
          }));
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode, t, dispatch]);

  /* State */
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [confirmMoveOutMessage, setConfirmMoveOutMessage] = useState("");
  const [moveResidentToMove, setMoveResidentToMove] = useState("");
  const [popoverID, setPopoverID] = useState("");
  const [formError, setFormError] = useState({
    [FormFieldIDs.firstName]: "",
    [FormFieldIDs.lastName]: "",
    [FormFieldIDs.city]: "",
    name_conflict: "",
  });

  const [formValues, setFormValues] = useState<FormState>({
    [FormFieldIDs.firstName]: resident.first_name,
    [FormFieldIDs.lastName]: resident.last_name,
    [FormFieldIDs.addressOne]: resident.street ?? null,
    [FormFieldIDs.addressTwo]: resident.street2 ?? null,
    [FormFieldIDs.city]: resident.city ?? null,
    [FormFieldIDs.state]: resident.state ?? null,
    [FormFieldIDs.zipCode]: resident.postal_code ?? null,
    [FormFieldIDs.dateOfBirth]: resident.date_of_birth ?? null,
    [FormFieldIDs.phoneNumberOne]: {
      id: null,
      country_code: defaultRegionCode,
      phone_type: PhoneTypes.HOME,
      phone_number: null,
    },
    [FormFieldIDs.phoneNumberTwo]: {
      id: null,
      country_code: defaultRegionCode,
      phone_type: PhoneTypes.MOBILE,
      phone_number: null,
    },
  });

  /* Effects */
  // Initialize phone numbers
  useEffect(() => {
    const initialVals: FormState = {
      [FormFieldIDs.phoneNumberOne]: {
        id: null,
        country_code: defaultRegionCode,
        phone_type: PhoneTypes.HOME,
        phone_number: null,
      },
      [FormFieldIDs.phoneNumberTwo]: {
        id: null,
        country_code: defaultRegionCode,
        phone_type: PhoneTypes.MOBILE,
        phone_number: null,
      },
    };

    if (resident.phone_numbers && resident.phone_numbers.length > 0) {
      const phoneNumbersCopy = JSON.parse(
        JSON.stringify(resident.phone_numbers)
      );
      phoneNumbersCopy.sort(
        <T extends { priority?: number | null }>(a: T, b: T) =>
          Number(a.priority) - Number(b.priority)
      );

      if (phoneNumbersCopy.length > 0) {
        const phoneNumberOne = phoneNumbersCopy[0];
        const countryCode = phoneNumberOne.country_code
          ? String(
              getRegionCodeForCountryCode(Number(phoneNumberOne.country_code))
            )
          : defaultRegionCode;
        initialVals[FormFieldIDs.phoneNumberOne] = {
          id: phoneNumberOne.id,
          country_code: countryCode,
          phone_type: phoneNumberOne.phone_type ?? PhoneTypes.HOME,
          phone_number: phoneNumberOne.phone_number ?? null,
        };
      }
      if (phoneNumbersCopy.length > 1) {
        const phoneNumberTwo = phoneNumbersCopy[1];
        const countryCode = phoneNumberTwo.country_code
          ? String(
              getRegionCodeForCountryCode(Number(phoneNumberTwo.country_code))
            )
          : defaultRegionCode;
        initialVals[FormFieldIDs.phoneNumberTwo] = {
          id: phoneNumberTwo.id,
          country_code: countryCode,
          phone_type: phoneNumberTwo.phone_type ?? PhoneTypes.HOME,
          phone_number: phoneNumberTwo.phone_number ?? null,
        };
      }
    }
    if (formError.name_conflict === "") {
      setFormValues((prevVal) => ({ ...prevVal, ...initialVals }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resident, resident?.phone_numbers]);

  /* Methods */

  // Open dropdown list for some of the input elements
  const handleOpenFilter = (
    event: React.MouseEvent<HTMLDivElement>,
    id: string
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverID(id);
  };

  const handleChange = (value: string, formField: FormFieldIDs) => {
    setFormValues((prevVal) => ({
      ...prevVal,
      [formField]: value.trimStart(),
    }));
    setFormError((prevVal) => ({
      ...prevVal,
      name_conflict: "",
    }));
    setAnchorEl(null);
  };

  const handleChangePhone = (
    value: UnitPhoneNumber,
    formField: FormFieldIDs
  ) => {
    setFormValues((prevVal) => ({ ...prevVal, [formField]: value }));
  };

  const handleChangeDateOfBirth = (date: string) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [FormFieldIDs.dateOfBirth]: date,
    }));
  };

  const getChangedData = (oldResident: IResident, newValues: FormState) => {
    const changedData: IResident = {
      id: oldResident.id,
      first_name: oldResident.first_name,
      last_name: oldResident.last_name,
      city: oldResident.city,
      postal_code: oldResident.postal_code,
      state: oldResident.state,
      street: oldResident.street,
      street2: oldResident.street2,
      date_of_birth: oldResident.date_of_birth,
    };
    let newData = false;
    if (
      oldResident.first_name !== newValues[FormFieldIDs.firstName] &&
      newValues[FormFieldIDs.firstName]
    ) {
      changedData.first_name = newValues[FormFieldIDs.firstName] as string;
      newData = true;
    }
    if (
      oldResident.last_name !== newValues[FormFieldIDs.lastName] &&
      newValues[FormFieldIDs.lastName]
    ) {
      changedData.last_name = newValues[FormFieldIDs.lastName] as string;
      newData = true;
    }
    if (oldResident.city !== newValues[FormFieldIDs.city]) {
      changedData.city = newValues[FormFieldIDs.city];
      newData = true;
    }
    if (oldResident.postal_code !== newValues[FormFieldIDs.zipCode]) {
      changedData.postal_code = newValues[FormFieldIDs.zipCode];
      newData = true;
    }
    if (oldResident.state !== newValues[FormFieldIDs.state]) {
      changedData.state = newValues[FormFieldIDs.state];
      newData = true;
    }
    if (oldResident.street !== newValues[FormFieldIDs.addressOne]) {
      changedData.street = newValues[FormFieldIDs.addressOne];
      newData = true;
    }
    if (oldResident.street2 !== newValues[FormFieldIDs.addressTwo]) {
      changedData.street2 = newValues[FormFieldIDs.addressTwo];
      newData = true;
    }

    if (oldResident.date_of_birth !== newValues[FormFieldIDs.dateOfBirth]) {
      changedData.date_of_birth = newValues[FormFieldIDs.dateOfBirth];
      newData = true;
    }

    const updatedData: { [key: string]: any } = { ...changedData };
    Object.keys(updatedData).forEach((key) => {
      if (updatedData[key] === null) {
        delete updatedData[key];
      }
    });

    return newData ? (updatedData as IResident) : undefined;
  };

  const getMoveOutMessage = () => {
    const confirmMoveOutMessage = t("confirm_move_resident", {
      firstName: resident.first_name,
      lastName: resident.last_name,
      unitName: unit ? unit.name : "",
    });
    return confirmMoveOutMessage;
  };

  // Menu methods
  const handleOpenMoreMenu = () => (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuExited = () => {};

  const handleCloseDropdown = () => {
    setMenuAnchorEl(null);
    setMoveResidentToMove("");
  };

  const handleConfirmMoveResident = async () => {
    setMoveResidentToMove(resident.id);
    setConfirmMoveOutMessage(getMoveOutMessage());
    setMenuAnchorEl(null);
  };

  const handleMoveResident = async () => {
    if (resident && communityID) {
      const requestData: IDeleteResident = {
        resident_id: resident.id,
        community_id: communityID,
      };
      dispatch(deleteResident(requestData));
      setMoveResidentToMove("");
    }
  };

  const handlePhoneNumberUpdate = async (
    oldPhoneNumbers: IPhoneNumber[] | null | undefined,
    newValues: FormState,
    resident_id: string,
    unit_id: string
  ) => {
    const phoneOperation = getPhoneOperationsArr(
      oldPhoneNumbers,
      [
        newValues[FormFieldIDs.phoneNumberOne],
        newValues[FormFieldIDs.phoneNumberTwo],
      ] as const,
      "resident"
    );

    for (const item of phoneOperation) {
      switch (item.op) {
        case phoneOperations.add:
          await dispatch(
            createPhoneNumber({
              unit_id: unit_id,
              phoneNumber: getNewPhoneNumber(item.phone, resident_id),
            })
          );
          break;
        case phoneOperations.update:
          await dispatch(
            updatePhoneNumber({
              unit_id: unit_id,
              resident_id: resident_id,
              phoneNumber: getUpdatedPhoneNumber(item.phone, oldPhoneNumbers),
            })
          );
          break;
        case phoneOperations.delete:
          if (item.phone.id) {
            await dispatch(
              deletePhoneNumber({
                unit_id: unit_id,
                resident_id: resident_id,
                phoneNumberID: item.phone.id,
              })
            );
          }
          break;
        case phoneOperations.same:
          // check if priority changed?
          await dispatch(
            updatePhoneNumber({
              unit_id: unit_id,
              resident_id: resident_id,
              phoneNumber: getUpdatedPhoneNumber(item.phone, oldPhoneNumbers),
            })
          );
          break;
        default:
          break;
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (communityID === undefined) return;

    const residentData = resident && getChangedData(resident, formValues);

    if (residentData) {
      const requestData: IUpdateResident = {
        community_id: communityID,
        resident: residentData,
      };

      dispatch(updateResident(requestData));
    }

    // Handles the adding, updating and deleting resident phone numbers
    if (resident && resident.unit_id) {
      handlePhoneNumberUpdate(
        resident.phone_numbers,
        formValues,
        resident.id,
        resident.unit_id
      );
    }
  };
  /* List values */
  const stateList = useMemo(() => getStatesList(communityCountry), [
    communityCountry,
  ]);

  const getStateLabel = (id: string | null) => {
    if (id === null) return "-";
    return stateList.find((state) => state.id === id)?.label ?? "-";
  };

  // Validate if required field has value
  const validateNotEmpty = (id: FormFieldIDs) => {
    if (formValues[id] === null || formValues[id] === "") {
      setFormError((prevVal) => ({ ...prevVal, [id]: "requiredVal" }));
    } else {
      setFormError((prevVal) => ({ ...prevVal, [id]: "" }));
    }
  };

  // Enable save button
  const checkFormValidity = (values: FormState, homeCareCommunity: boolean) => {
    let enabled = true;

    if (
      values[FormFieldIDs.firstName] === null ||
      values[FormFieldIDs.firstName] === ""
    ) {
      enabled = false;
    }
    if (
      values[FormFieldIDs.lastName] === null ||
      values[FormFieldIDs.lastName] === ""
    ) {
      enabled = false;
    }

    if (
      homeCareCommunity &&
      (values[FormFieldIDs.city] === null || values[FormFieldIDs.city] === "")
    ) {
      enabled = false;
    }

    return enabled;
  };

  // Enable save button
  const checkFormChanged = (
    oldData: IResident | undefined,
    values: FormState
  ) => {
    let changed = false;
    if (oldData === undefined) return changed;

    return (
      oldData.first_name !== values[FormFieldIDs.firstName] ||
      oldData.last_name !== values[FormFieldIDs.lastName] ||
      oldData.street !== values[FormFieldIDs.addressOne] ||
      oldData.street2 !== values[FormFieldIDs.addressTwo] ||
      oldData.city !== values[FormFieldIDs.city] ||
      oldData.state !== values[FormFieldIDs.state] ||
      oldData.postal_code !== values[FormFieldIDs.zipCode] ||
      oldData.date_of_birth !== values[FormFieldIDs.dateOfBirth]
    );
  };

  const checkPhoneNumbersChanged = (
    oldData: IResident["phone_numbers"],
    values: FormState
  ) => {
    let changed = false;
    if (oldData === undefined) return changed;
    const phone1 = values[FormFieldIDs.phoneNumberOne];
    if (phone1.id) {
      if (phone1.phone_number) {
        const oldPhone = oldData?.find((oldPhone) => oldPhone.id === phone1.id);
        const adjustedPhone1 = adjustPhoneNumber(phone1, "resident");
        if (
          oldPhone &&
          (oldPhone.phone_number !== adjustedPhone1.phone_number ||
            oldPhone.phone_type !== adjustedPhone1.phone_type ||
            oldPhone.country_code !== adjustedPhone1.country_code)
        ) {
          changed = true;
        }
      } else {
        changed = true;
      }
    } else {
      if (phone1.phone_number) {
        changed = true;
      }
    }
    const phone2 = values[FormFieldIDs.phoneNumberTwo];
    if (phone2.id) {
      if (phone2.phone_number) {
        const oldPhone = oldData?.find((oldPhone) => oldPhone.id === phone2.id);
        const adjustedPhone2 = adjustPhoneNumber(phone2, "resident");
        if (
          oldPhone &&
          (oldPhone.phone_number !== adjustedPhone2.phone_number ||
            oldPhone.phone_type !== adjustedPhone2.phone_type ||
            oldPhone.country_code !== adjustedPhone2.country_code)
        ) {
          changed = true;
        }
      } else {
        changed = true;
      }
    } else {
      if (phone2.phone_number) {
        changed = true;
      }
    }
    return changed;
  };

  const enableSubmit =
    checkFormValidity(formValues, homeCareCommunity) &&
    (checkFormChanged(resident, formValues) ||
      checkPhoneNumbersChanged(resident?.phone_numbers, formValues));

  return (
    <Paper className={classes.editResidentSettings} elevation={3} square>
      {!homeCareCommunity && (
        <div className={classes.menu}>
          <IconButton
            aria-label="more user options"
            aria-haspopup="true"
            onClick={handleOpenMoreMenu()}
            className={classes.menuButton}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      )}
      <h2 className={classes.sectionTitle}>{t("resident_settings")}</h2>
      <form
        id="resident-settings-form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        className={classes.formContainer}
      >
        <ul className={classes.fieldList}>
          <FormRow inputKey="first_name" required>
            <Input
              id={FormFieldIDs.firstName}
              type={"text"}
              className={classes.inputWidth}
              value={formValues[FormFieldIDs.firstName] ?? ""}
              onChange={(e) => {
                handleChange(e.currentTarget.value, FormFieldIDs.firstName);
              }}
              error={
                (formError[FormFieldIDs.firstName] !== "" &&
                  formError[FormFieldIDs.firstName] !== null) ||
                formError["name_conflict"] !== ""
              }
              onBlur={() => validateNotEmpty(FormFieldIDs.firstName)}
              inputProps={{ maxLength: NAME_CHARACTER_LIMIT }}
            />
            {formError["name_conflict"] && (
              <FormHelperText className={classes.helperText}>
                {formError["name_conflict"]}
              </FormHelperText>
            )}
          </FormRow>
          <FormRow inputKey="last_name" required>
            <Input
              id={FormFieldIDs.lastName}
              type={"text"}
              className={classes.inputWidth}
              value={formValues[FormFieldIDs.lastName] ?? ""}
              onChange={(e) => {
                handleChange(e.currentTarget.value, FormFieldIDs.lastName);
              }}
              error={
                (formError[FormFieldIDs.lastName] !== "" &&
                  formError[FormFieldIDs.lastName] !== null) ||
                formError["name_conflict"] !== ""
              }
              onBlur={() => validateNotEmpty(FormFieldIDs.lastName)}
              inputProps={{ maxLength: NAME_CHARACTER_LIMIT }}
            />
          </FormRow>
          <FormRow inputKey="street">
            <Input
              id={FormFieldIDs.addressOne}
              type={"text"}
              className={classes.inputWidth}
              value={formValues[FormFieldIDs.addressOne] ?? ""}
              onChange={(e) => {
                handleChange(e.currentTarget.value, FormFieldIDs.addressOne);
              }}
            />
          </FormRow>
          <FormRow inputKey="street2">
            <Input
              id={FormFieldIDs.addressTwo}
              type={"text"}
              className={classes.inputWidth}
              value={formValues[FormFieldIDs.addressTwo] ?? ""}
              onChange={(e) => {
                handleChange(e.currentTarget.value, FormFieldIDs.addressTwo);
              }}
            />
          </FormRow>
          <FormRow inputKey="city" required={homeCareCommunity}>
            <Input
              id={FormFieldIDs.city}
              type={"text"}
              className={classes.inputWidth}
              value={formValues[FormFieldIDs.city] ?? ""}
              onChange={(e) => {
                handleChange(e.currentTarget.value, FormFieldIDs.city);
              }}
              error={
                (formError[FormFieldIDs.city] !== "" &&
                  formError[FormFieldIDs.city] !== null) ||
                formError["name_conflict"] !== ""
              }
              onBlur={() => {
                if (homeCareCommunity) validateNotEmpty(FormFieldIDs.city);
              }}
            />
          </FormRow>
          <FormRow inputKey="state">
            <DropdownInput
              id={FormFieldIDs.state}
              value={
                formValues[FormFieldIDs.state]
                  ? getStateLabel(formValues[FormFieldIDs.state] as string)
                  : ""
              }
              handleClick={handleOpenFilter}
            />
          </FormRow>
          <FormRow inputKey="zip_code">
            <Input
              id={FormFieldIDs.zipCode}
              type={"text"}
              className={classes.inputWidth}
              value={formValues[FormFieldIDs.zipCode] ?? ""}
              onChange={(e) => {
                handleChange(e.currentTarget.value, FormFieldIDs.zipCode);
              }}
            />
          </FormRow>
          <FormRow inputKey="date_of_birth">
            <DateOfBirthDatePicker
              dateOfBirth={formValues[FormFieldIDs.dateOfBirth] ?? ""}
              saveDateChange={handleChangeDateOfBirth}
            />
          </FormRow>
          <div className={classes.phoneNumbersTitle}>
            {t("phone_numbers").toUpperCase()}
          </div>
          <div className={classes.phoneNumbersContainer}>
            <PhoneNumberPicker
              fieldID={FormFieldIDs.phoneNumberOne}
              labelComplement={"1."}
              defaultCountryCode={defaultRegionCode}
              phoneNumber={formValues[FormFieldIDs.phoneNumberOne]}
              formFull={true}
              onChange={(value: UnitPhoneNumber) =>
                handleChangePhone(value, FormFieldIDs.phoneNumberOne)
              }
            />
            <PhoneNumberPicker
              fieldID={FormFieldIDs.phoneNumberTwo}
              labelComplement={"2."}
              defaultCountryCode={defaultRegionCode}
              phoneNumber={formValues[FormFieldIDs.phoneNumberTwo]}
              formFull={true}
              onChange={(value: UnitPhoneNumber) =>
                handleChangePhone(value, FormFieldIDs.phoneNumberTwo)
              }
            />
          </div>
        </ul>
        <Menu
          anchorEl={menuAnchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(menuAnchorEl)}
          onClose={handleCloseMoreMenu}
          classes={{
            list: classes.menuList,
          }}
          TransitionProps={{
            onExited: handleMenuExited,
          }}
        >
          <MenuItem
            className={classes.menuItem}
            onClick={handleConfirmMoveResident}
          >
            {t("move_out")}
          </MenuItem>
        </Menu>
        <ConfirmDialog
          dialogType={DialogTypes.warning}
          toggle={moveResidentToMove !== ""}
          title={t("confirm_move_resident_title")}
          message={confirmMoveOutMessage}
          possitiveText={t("yes")}
          negativeText={t("no")}
          positiveAction={() => handleMoveResident()}
          negativeAction={() => void 0}
          onClose={handleCloseDropdown}
        />
        <div className={classes.buttonContainer}>
          <Button
            type="submit"
            variant={!enableSubmit ? "outlined" : "contained"}
            color="primary"
            disabled={!enableSubmit}
            classes={{
              root: classes.buttonRoot,
            }}
          >
            {t("save")}
          </Button>
        </div>
      </form>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ zIndex: 1200 }}
      >
        <>
          {popoverID === FormFieldIDs.state && (
            <GenericPicker
              selectedValue={formValues["state"] ?? null}
              optionList={stateList}
              localize={false}
              handleChange={(option) =>
                handleChange(option, FormFieldIDs.state)
              }
            />
          )}
          {popoverID === "" && null}
        </>
      </Popover>
    </Paper>
  );
};

interface IProps {
  resident: IResident;
  homeCareCommunity: boolean;
}
