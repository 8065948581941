import { createAsyncThunk } from "@reduxjs/toolkit";
import { UnitTypes } from "../../helpers/constants";
import {
  AddEmergencyContact,
  commonServices,
  ContactPositionData,
  IAddContactData,
  IDeleteContactPhoneData,
  IResidentData,
  IUpdateContactData,
  IUpdateContactPhoneData,
} from "../../services/common.services";
import { IUnit } from "../../services/dashboard.services";
import { IPhoneNumber } from "../../services/header.services";
import { IFailedRequest } from "../../services/login.services";
import {
  IAddContactPhoneNumber,
  IAddPhoneNumberData,
  IAddResident,
  ICommunityConfigData,
  ICommunityGroupConfig,
  IDeleteResident,
  IExpirationUnitData,
  IPauseUnitData,
  ISecurityNotificationPauseData,
  IUnitConfigData,
  IUnitEventConfigData,
  IUnitGroupConfig,
  IUpdateResident,
  settingsServices,
} from "../../services/settings.services";

export const loadEventConfig = createAsyncThunk(
  "settings/loadEventConfig",
  async (requestData: {
    communityID: string;
    eventType: string;
    eventConfigID: string;
  }) => {
    const [eventConfig, unitEventConfig] = await Promise.all([
      settingsServices.getEventConfig(requestData.eventConfigID),
      settingsServices.getUnitConfigByEventType({
        communityID: requestData.communityID,
        eventType: requestData.eventType,
      }),
    ]);
    return { eventConfig, unitEventConfig };
  }
);

export const updateCommunityEventConfig = createAsyncThunk(
  "settings/updateCommunityEventConfig",
  async (configData: ICommunityConfigData) => {
    const updateEnabled = configData.is_enabled !== undefined;
    const configEnabled = configData.configuration !== undefined;
    if (updateEnabled && configEnabled) {
      const [enableResponse, configResponse] = await Promise.all([
        settingsServices.enableCommunityEventConfig(configData),
        settingsServices.updateCommunityEventConfig(configData),
      ]);

      return { enableResponse, configResponse };
    } else if (updateEnabled) {
      const response = await settingsServices.enableCommunityEventConfig(
        configData
      );

      return { enableResponse: response };
    } else if (configEnabled) {
      const response = await settingsServices.updateCommunityEventConfig(
        configData
      );

      return { configResponse: response };
    } else {
      const response = await settingsServices.updateCommunityEventConfig(
        configData
      );
      return { configResponse: response };
    }
  }
);

export const loadUnitConfigByEvent = createAsyncThunk(
  "settings/loadUnitConfigByEvent",
  async (requestData: IUnitConfigData) => {
    const response = await settingsServices.getUnitConfigByEventType(
      requestData
    );
    return response;
  }
);

export type ICreateUnitData = Partial<IUnit> & {
  resident?: IAddResident;
  phoneNumbers?: IAddPhoneNumberData[];
};

export const createUnit = createAsyncThunk(
  "settings/createUnit",
  async (requestData: ICreateUnitData) => {
    if (requestData.unit_type === UnitTypes.HELP_AT_HOME) {
      const newUnit = await settingsServices.createUnit({
        community_id: requestData.community_id,
        unit_type: requestData.unit_type,
        time_zone: requestData.time_zone,
      });

      if (requestData.resident !== undefined) {
        const residentData: ICreateUnitData["resident"] = {
          ...requestData.resident,
          unit_id: newUnit.id,
        };

        const newResident = await settingsServices.addResident({
          communityID: requestData.community_id ?? "",
          resident: residentData,
        });

        if (
          requestData.phoneNumbers !== undefined &&
          requestData.phoneNumbers.length > 0 &&
          newResident
        ) {
          requestData.phoneNumbers.forEach(async (phoneNumber) => {
            const newPhoneNumber = phoneNumber;
            phoneNumber.resident_id = newResident.id;
            await settingsServices.addPhoneNumber(newPhoneNumber);
          });
        }

        return { newUnit: newUnit, newResident: newResident };
      }
      return { newUnit };
    } else if (requestData.unit_type === UnitTypes.RESIDENT) {
      const response = await settingsServices.createUnit({
        community_id: requestData.community_id,
        name: requestData.name,
        unit_type: requestData.unit_type,
        time_zone: requestData.time_zone,
      });

      return { newUnit: response };
    }
  }
);

export const updateCommunityUnit = createAsyncThunk(
  "settings/updateCommunityUnit",
  async (unit: Partial<IUnit>) => {
    const response = await settingsServices.updateCommunityUnit(unit);

    return response;
  }
);

export const deleteCommunityUnit = createAsyncThunk(
  "settings/deleteCommunityUnit",
  async (unitID: string) => {
    const response = await settingsServices.deleteCommunityUnit(unitID);

    return response;
  }
);

export const updateUnitConfigByEventType = createAsyncThunk(
  "settings/updateUnitConfigByEventType",
  async (configData: IUnitEventConfigData) => {
    const updateEnabled = configData.is_enabled !== undefined;
    const configEnabled = configData.configuration !== undefined;
    if (updateEnabled && configEnabled) {
      const [enableResponse, configResponse] = await Promise.all([
        settingsServices.enableUnitEventConfig(configData),
        settingsServices.updateUnitConfigByEventType(configData),
      ]);

      return { enableResponse, configResponse };
    } else if (updateEnabled) {
      const response = await settingsServices.enableUnitEventConfig(configData);

      return { enableResponse: response };
    } else if (configEnabled) {
      const response = await settingsServices.updateUnitConfigByEventType(
        configData
      );

      return { configResponse: response };
    } else {
      const response = await settingsServices.updateUnitConfigByEventType(
        configData
      );
      return { configResponse: response };
    }
  }
);

// Loads the resident information along with its contacts
export const getResidentInfo = createAsyncThunk(
  "settings/getResidentInfo",
  async (residentData: IResidentData) => {
    const resident = await commonServices.getResidentContacts(residentData);
    return resident;
  }
);

export type ICreateResident = Pick<IUnit, "community_id"> & {
  resident: IAddResident;
  phoneNumbers?: IAddPhoneNumberData[];
};

export const addResident = createAsyncThunk(
  "settings/addResident",
  async (requestData: ICreateResident) => {
    const { community_id, resident, phoneNumbers } = requestData;

    const response = await settingsServices.addResident({
      communityID: community_id ?? "",
      resident: resident,
    });

    if (phoneNumbers !== undefined && phoneNumbers.length > 0 && response) {
      const addedPhoneNumbers = await Promise.all(
        phoneNumbers.map(async (phoneNumber) => {
          const newPhoneNumber = phoneNumber;
          phoneNumber.resident_id = response.id;
          return await settingsServices.addPhoneNumber(newPhoneNumber);
        })
      );
      response.phone_numbers = addedPhoneNumbers;
    }

    return response;
  }
);

export const updateResident = createAsyncThunk(
  "settings/updateResident",
  async (requestData: IUpdateResident) => {
    const response = await settingsServices.updateResident({
      community_id: requestData.community_id,
      resident: requestData.resident,
    });
    return response;
  }
);

export const deleteResident = createAsyncThunk(
  "settings/deleteResident",
  async (requestData: IDeleteResident) => {
    const response = await settingsServices.deleteResident({
      resident_id: requestData.resident_id,
      community_id: requestData.community_id,
    });

    return response;
  }
);

type INewPhoneNumber = IPhoneNumber & {
  resident_id: string;
};
export interface IAddPhoneNumber {
  unit_id: string;
  phoneNumber: Omit<INewPhoneNumber, "id">;
}

export const createPhoneNumber = createAsyncThunk(
  "settings/createPhoneNumber",
  async (requestData: IAddPhoneNumber) => {
    const response = await settingsServices.addPhoneNumber(
      requestData.phoneNumber
    );
    return response;
  }
);
export interface IUpdatePhoneNumber {
  unit_id: string;
  resident_id: string;
  phoneNumber: IPhoneNumber;
}

export const updatePhoneNumber = createAsyncThunk(
  "settings/updatePhoneNumber",
  async (requestData: IUpdatePhoneNumber) => {
    const response = await settingsServices.updateResidentPhoneNumber(
      requestData
    );

    return response;
  }
);

export interface IDeletePhoneNumbers {
  unit_id: string;
  resident_id: string;
  phoneNumberID: string;
}

export const deletePhoneNumber = createAsyncThunk(
  "settings/deletePhoneNumber",
  async (requestData: IDeletePhoneNumbers) => {
    const response = await settingsServices.deleteResidentPhoneNumber(
      requestData
    );

    return response;
  }
);

export type IAddResidentContactData = {
  contact: IAddContactData;
  phoneNumbers?: IAddPhoneNumberData[];
};

export const addResidentContact = createAsyncThunk(
  "settings/addResidentContact",
  async (requestData: IAddResidentContactData) => {
    const { contact, phoneNumbers } = requestData;

    const response = await commonServices.addResidentContact(contact);

    if (phoneNumbers !== undefined && phoneNumbers.length > 0 && response) {
      const addedPhoneNumbers = await Promise.all(
        phoneNumbers.map(async (phoneNumber) => {
          const newPhoneNumber: IAddContactPhoneNumber = {
            ...phoneNumber,
            contact_id: response.id,
          };
          return await commonServices.addContactPhoneNumber(newPhoneNumber);
        })
      );
      response.phone_numbers = addedPhoneNumbers;
    }
    return response;
  }
);

export const updateResidentContact = createAsyncThunk(
  "settings/updateResidentContact",
  async (requestData: IUpdateContactData) => {
    const response = await commonServices.updateResidentContact(requestData);

    return response;
  }
);

export const deleteResidentContact = createAsyncThunk(
  "settings/deleteResidentContact",
  async (requestData: { residentID: string; contactID: string }) => {
    const response = await commonServices.deleteResidentContact(
      requestData.contactID
    );

    return response;
  }
);

export const addContactPhoneNumber = createAsyncThunk(
  "settings/createPhoneNumber",
  async (requestData: {
    residentID: string;
    phoneNumber: IAddContactPhoneNumber;
  }) => {
    const response = await commonServices.addContactPhoneNumber(
      requestData.phoneNumber
    );
    return response;
  }
);

export const updateContactPhoneNumber = createAsyncThunk(
  "settings/updateContactPhoneNumber",
  async (requestData: {
    residentID: string;
    phoneData: IUpdateContactPhoneData;
  }) => {
    const response = await commonServices.updateContactPhoneNumber(
      requestData.phoneData
    );

    return response;
  }
);

export const deleteContactPhoneNumber = createAsyncThunk(
  "settings/deleteContactPhoneNumber",
  async (
    requestData: {
      residentID: string;
      contactID: string;
      deleteData: IDeleteContactPhoneData;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await commonServices.deleteContactPhoneNumber(
        requestData.deleteData
      );

      return response;
    } catch (err) {
      return rejectWithValue((err as unknown) as IFailedRequest);
    }
  }
);

export const getEmergencyContacts = createAsyncThunk(
  "settings/getEmergencyContacts",
  async (residentID: string) => {
    const response = await commonServices.getEmergencyContacts(residentID);
    return response;
  }
);

export const addEmergencyContact = createAsyncThunk(
  "settings/addEmergencyContact",
  async (contactData: AddEmergencyContact) => {
    const response = await commonServices.addEmergencyContact(contactData);

    return response;
  }
);

export const updateEmergencyContactsPosition = createAsyncThunk(
  "settings/updateEmergencyContactsPosition",
  async (contactData: ContactPositionData) => {
    const response = await commonServices.updateEmergencyContactsPosition(
      contactData
    );

    return response;
  }
);

export const deleteEmergencyContact = createAsyncThunk(
  "settings/deleteEmergencyContact",
  async (emergencyID: string) => {
    const response = await commonServices.deleteEmergencyContact(emergencyID);

    return response;
  }
);

export const loadUnitGroupsByEventType = createAsyncThunk(
  "settings/loadUnitGroupsByEventType",
  async (communityID: string) => {
    const response = await settingsServices.getUnitGroupsByEventType(
      communityID
    );

    return response;
  }
);

export const updateUnitGroupsConfig = createAsyncThunk(
  "settings/updateUnitGroupConfig",
  async (groupConfigData: IUnitGroupConfig) => {
    const response = await settingsServices.updateUnitGroupsConfig(
      groupConfigData
    );

    return response;
  }
);

export const updateCommunityGroupsConfig = createAsyncThunk(
  "settings/updateCommunityGroupsConfig",
  async (groupConfigData: ICommunityGroupConfig) => {
    const response = await settingsServices.updateCommunityGroupsConfig(
      groupConfigData
    );

    return response;
  }
);

export const toggleSettingsPauseUnit = createAsyncThunk(
  "settings/toggleSettingsPauseUnit",
  async (togglePauseUnitData: IPauseUnitData) => {
    const response = await settingsServices.togglePauseUnit(
      togglePauseUnitData
    );

    return response;
  }
);

export const setUnitPauseExpiration = createAsyncThunk(
  "settings/setUnitPauseExpiration",
  async (expirationUnitData: IExpirationUnitData) => {
    const response = await settingsServices.setUnitPauseExpiration(
      expirationUnitData
    );

    return response;
  }
);

export const setSecurityNotificationPause = createAsyncThunk(
  "settings/setSecurityNotificationPause",
  async (securityNotificationData: ISecurityNotificationPauseData) => {
    const response = await settingsServices.setUnitSecurityNotification(
      securityNotificationData
    );

    return response;
  }
);
