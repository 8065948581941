import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import {
  capitalize,
  EventStatus,
  EventTypes,
  ReadStatus,
  ReducerStates,
  RESIDENT_FIRST_NAME_LENGTH,
  sortAlphabetical,
  TabTypes,
  UnitTypes,
} from "../../helpers/constants";
import {
  dashboardServices,
  IEvent,
  IEventNotes,
  IResident,
  IUnit,
  IUnitDashboard,
  IWatchedFlagData,
} from "../../services/dashboard.services";
import { IUserDetails } from "../../services/header.services";
import {
  assignEventResponder,
  postNewComment,
  resolveEventWithPassword,
  resolveEventWithPin,
  toggleUnitPaused,
} from "../app/asyncThunkActions";
import {
  createUnit,
  deleteCommunityUnit,
  deleteResident,
} from "../settings/settingsThunks";
import {
  changeSelectedCommunity,
  changeSelectedOrganization,
  setAllNotifications,
} from "../header/headerSlice";
import { IUnitActivityData } from "./eventDataTypes";
import { logout } from "../login/loginSlice";
import { addResident, updateResident } from "../settings/settingsThunks";
import {
  loadAllUnitUserData,
  loadOrganizationData,
} from "../header/headerThunks";
import { StorageKeys } from "../../services/constants";

export interface IEnhancedUnit extends IUnit {
  notVisible?: boolean;
}
export interface IResolveEventDataPin {
  email: string;
  pin: string;
  eventID: string;
  resolutionID: string;
}
export interface IResolveEventDataPassword {
  email: string;
  password: string;
  eventID: string;
  resolutionID: string;
}

export const isHardEvent = (eventEl: IEvent) =>
  eventEl.notification_type === "hard";
export const isSoftEvent = (eventEl: IEvent) =>
  eventEl.notification_type === "soft";
export const isActiveUser = <
  T extends Pick<IUserDetails, "first_name" | "last_name">
>(
  user: T
) => user.first_name !== null && user.last_name !== null;

const getFirstName = (name: string | null, fullName: boolean | undefined) => {
  if (name === null) return "";
  // For short names, ignore full name property
  if (name.length <= RESIDENT_FIRST_NAME_LENGTH) return name;
  if (fullName) return name;
  //Get the first part of the name and check if its longer than allowed length
  const nameFirstPart = name.split(" ")[0];
  return nameFirstPart.length > RESIDENT_FIRST_NAME_LENGTH
    ? `${nameFirstPart.slice(0, RESIDENT_FIRST_NAME_LENGTH)}.`
    : nameFirstPart;
};

export type ResidentNameOptions = {
  fullFirstName?: boolean;
  fullLastName?: boolean;
};
// Get a unit resident's name based on an array of IResidents
export const getResidentName = (
  residents: IResident[] | null | undefined,
  options?: ResidentNameOptions
) => {
  if (residents !== undefined && residents !== null) {
    if (residents.length > 0) {
      const resident = residents[0];
      let firstName = getFirstName(resident.first_name, options?.fullFirstName);

      let lastName =
        resident.last_name !== null
          ? options?.fullLastName
            ? capitalize(resident.last_name)
            : resident.last_name.slice(0, 1).toUpperCase()
          : "";

      let fullName = "";
      if (firstName !== "") {
        fullName = capitalize(firstName);
      }
      if (lastName !== "") {
        if (fullName !== "") {
          fullName = fullName.concat(" ", lastName);
        } else {
          fullName = lastName;
        }
      }
      return fullName;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

// Get a unit resident's name based on an array of IResidents
export const getResidentCity = (residents?: IResident[] | null) => {
  if (residents !== undefined && residents !== null && residents.length > 0) {
    const resident = residents[0];
    let city = resident.city ?? "";
    if (city !== "") {
      city = capitalize(city);
    }
    return city;
  } else {
    return "";
  }
};

export const isHelpAtHome = (unit?: IEnhancedUnit) => {
  if (unit === undefined) return;
  return unit.unit_type === UnitTypes.HELP_AT_HOME;
};

export const sortHelpAtHome = <T extends { residents: IResident[] | null }>(
  a: T,
  b: T
) => {
  if (a.residents === null || a.residents.length === 0) return 1;
  if (b.residents === null || b.residents.length === 0) return -1;

  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });

  const residentNameA = getResidentName(a.residents, {
    fullFirstName: false,
  });
  const residentCityA = getResidentCity(a.residents);

  const residentNameB = getResidentName(b.residents, {
    fullFirstName: false,
  });
  const residentCityB = getResidentCity(b.residents);

  if (collator.compare(residentCityA, residentCityB) !== 0) {
    return collator.compare(residentCityA, residentCityB);
  }
  if (collator.compare(residentNameA, residentNameB) !== 0) {
    return collator.compare(residentNameA, residentNameB);
  }
  // names must be equal
  return 0;
};

export const sortSeniorCommunity = <T extends { name: string }>(a: T, b: T) => {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });

  return collator.compare(a.name, b.name);
};

/**
 * Sorts the provided units array 
 * @param {EnhancedUnit[] | IUnit[]} units units array
 * @returns {EnhancedUnit[] | IUnit[]} A filtered unit array
 * 
 * Sorts in the following way
 * - If all units are help at home, first by resident.city & resident.first name & resident.last_name 
 * - If mixed units (help at home, common and resident), sort first by unit_type:resident & unit.number followed by unit_type:common & unit.number, sort 
    next by unit_type:help_at_home & resident.city & resident.first_name & resident.last_name
 * - If help at home unit has no resident or is not installed, sort at the end
 * 
 */
export const sortUnits = (units?: IEnhancedUnit[] | IUnit[]) => {
  if (units === undefined) return [];

  const unitCopy: typeof units = JSON.parse(JSON.stringify(units));

  // All units are help at home
  const allHelpAtHome = units.every((unit) => isHelpAtHome(unit));
  const allResident = units.every(
    (unit) => unit.unit_type === UnitTypes.RESIDENT
  );

  // Order units depending on how the unit types are mixed
  if (allHelpAtHome) {
    return unitCopy.sort((a, b) => sortHelpAtHome(a, b));
  } else if (allResident) {
    return unitCopy.sort((a, b) => sortSeniorCommunity(a, b));
  } else {
    const residentUnits = [...unitCopy]
      .filter((unit) => unit.unit_type === UnitTypes.RESIDENT)
      .sort((a, b) => sortSeniorCommunity(a, b));

    const otherUnits = [...unitCopy]
      .filter(
        (unit) => unit.unit_type !== UnitTypes.RESIDENT && !isHelpAtHome(unit)
      )
      .sort((a, b) => sortSeniorCommunity(a, b));

    const helpAtHomeUnits = [...unitCopy]
      .filter((unit) => isHelpAtHome(unit))
      .sort((a, b) => sortHelpAtHome(a, b));

    return [...residentUnits, ...otherUnits, ...helpAtHomeUnits];
  }
};

export interface IDashboardState {
  units?: IEnhancedUnit[];
  orgUnits: { [key: string]: IUnit[] };
  allUnits: { [key: string]: IUnit };
  communityEvents?: IEvent[];
  state: ReducerStates;
  firebaseState: ReducerStates;
  openedEventsTab: string[];
  unitDashboard: { [key: string]: IUnitDashboard | undefined };
  unitEvents: { [key: string]: IEvent[] | undefined };
  unitActivity: { [key: string]: IUnitActivityData | undefined };
  updateNotifications: boolean;
  startMonitoringNewEvents: boolean;
  addedEvent: boolean;
  errorCode: string;
  errorMessage?: string;
  openTray: boolean;
  minimizedTray: boolean;
  selectedTab?: number;
  tabs?: { id: string; type: TabTypes }[] | undefined;
  eventData: { [key: string]: { apiFailed: boolean } };
  communityFilter: string[];
  statusFilter: string[];
  userFilter: string[];
  isCommunityFiltered: boolean;
  isStatusFiltered: boolean;
  isUserFiltered: boolean;
  resolvedEvents?: IEvent[];
}

export const initialLDashboardState: IDashboardState = {
  units: undefined,
  orgUnits: {},
  allUnits: {},
  communityEvents: undefined,
  state: ReducerStates.IDLE,
  firebaseState: ReducerStates.IDLE,
  openedEventsTab: [],
  unitDashboard: {},
  unitEvents: {},
  unitActivity: {},
  updateNotifications: false,
  startMonitoringNewEvents: false,
  addedEvent: false,
  errorCode: "",
  errorMessage: "",
  openTray: false,
  minimizedTray: false,
  selectedTab: undefined,
  tabs: undefined,
  eventData: {},
  communityFilter: [],
  statusFilter: [],
  userFilter: [],
  isCommunityFiltered: false,
  isStatusFiltered: false,
  isUserFiltered: false,
  resolvedEvents: undefined,
};

// Gets the community units and events for the community
export const getCommunityData = createAsyncThunk(
  "dashboard/getCommunityData",
  async (community_id: string) => {
    const units = await dashboardServices.getUnits(community_id);
    return units;
  }
);

// Gets the community units and events for the community
export const getUnitStatus = createAsyncThunk(
  "dashboard/getUnitStatus",
  async (unit_id: string) => {
    const timeNow = DateTime.now().toUTC();
    const endTime = timeNow.toISO();
    const startTime = timeNow.minus({ hours: 12 }).toISO();

    const [dashboard, events, activity] = await Promise.all([
      dashboardServices.getUnitDashboard(unit_id),
      dashboardServices.getUnitEvents(unit_id),
      dashboardServices.getUnitActivityData(unit_id, startTime, endTime),
    ]);
    return { dashboard, events, activity };
  }
);

// Marks all warnings as read for the community
export const markAllWarningsRead = createAsyncThunk(
  "dashboard/markAllWarningsRead",
  async (community_id: string) => {
    const response = await dashboardServices.markAllWarningsRead(community_id);
    return response;
  }
);

// Toggles an unit watch flag
export const toggleUnitWatchFlag = createAsyncThunk(
  "dashboard/toggleUnitWatchFlag",
  async (watchFlagData: IWatchedFlagData) => {
    const response = await dashboardServices.toggleWatchedFlag(watchFlagData);
    return response;
  }
);

// Get an event by id
export const getEventDataDashboard = createAsyncThunk(
  "dashboard/getEventDataDashboard",
  async (eventID: string) => {
    const response = await dashboardServices.getEvent(eventID);
    return response;
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialLDashboardState,
  reducers: {
    clearUnitStatus(state, action) {
      delete state.unitDashboard[action.payload];
      delete state.unitEvents[action.payload];
      delete state.eventData[action.payload];
    },
    clearAllUnitStatus(state) {
      state.unitDashboard = {};
      state.unitEvents = {};
      state.eventData = {};
    },
    addTab(state, action) {
      const { id, type } = action.payload;
      if (state.tabs !== undefined) {
        const existingTabIndex = state.tabs.findIndex((tab) => tab.id === id);
        // If trying to add a tab that already exists, switch focus to it
        if (existingTabIndex === -1) {
          state.tabs = [...state.tabs, { id, type }];
          state.selectedTab = state.tabs.length - 1;
        } else {
          state.selectedTab = existingTabIndex;
          state.openTray = true;
          state.minimizedTray = false;
          return;
        }
      } else {
        state.tabs = [{ id, type }];
        state.selectedTab = 0;
      }
      state.openTray = true;
      state.minimizedTray = false;
    },
    closeTab(state, action) {
      const index = action.payload;
      let newSelectedTab = state.selectedTab;
      if (state.tabs !== undefined && newSelectedTab !== undefined) {
        let newTabs = [...state.tabs];
        const isCurrentlySelected = index === state.selectedTab;
        const isLast = index === newTabs.length - 1;
        // Check if tab to close is selected
        if (isCurrentlySelected) {
          newTabs.splice(index, 1);
          // If the new lenght of tabs is 0, then close tab tray
          if (newTabs.length > 0) {
            if (isLast) {
              newSelectedTab = newTabs.length - 1;
            } else {
              newSelectedTab = index;
            }
          } else {
            newSelectedTab = undefined;
            state.openTray = false;
          }
        } else {
          // If tab to delete is to the left of the selected tab, selectedTab index is reduced by one
          newTabs.splice(index, 1);
          if (index < newSelectedTab) {
            newSelectedTab = newSelectedTab - 1;
          }
        }
        state.selectedTab = newSelectedTab;
        state.tabs = newTabs;
      }
    },
    changeFocus(state, action) {
      const index = action.payload;
      state.selectedTab = index;
      state.openTray = true;
      state.minimizedTray = false;
    },
    minimizeTabTray(state) {
      state.openTray = false;
      state.minimizedTray = true;
    },
    maximizeTabTray(state) {
      state.openTray = true;
      state.minimizedTray = false;
    },
    closeTabTray(state) {
      state.openTray = false;
      state.minimizedTray = false;
      state.selectedTab = undefined;
      state.tabs = undefined;
    },
    setCommunityEvents(state, action) {
      const events: IEvent[] = action.payload;
      if (state.communityEvents === undefined) {
        state.communityEvents = events;
      } else {
        const eventIDs = state.communityEvents.map((item) => item.id);
        const eventsToUpdate = events.filter((incomingEvent) =>
          eventIDs.includes(incomingEvent.id)
        );

        const indexesEvents: [number, number][] = [];
        state.communityEvents.forEach((localEvent, index) => {
          const foreignIndex = eventsToUpdate.findIndex(
            (newEvent) => newEvent.id === localEvent.id
          );
          if (foreignIndex !== -1) {
            indexesEvents.push([index, foreignIndex]);
          }
        });

        indexesEvents.forEach((indexTuple) => {
          if (state.communityEvents === undefined) return;

          const oldEvent = state.communityEvents[indexTuple[0]];
          const newEvent = eventsToUpdate[indexTuple[1]];

          oldEvent.responder_id = newEvent.responder_id;
          oldEvent.status = newEvent.status;

          oldEvent.time_assigned =
            newEvent.time_assigned ?? oldEvent.time_assigned;
          oldEvent.time_resolved =
            newEvent.time_resolved ?? oldEvent.time_resolved;
          oldEvent.event_resolution_id =
            newEvent.event_resolution_id ?? oldEvent.event_resolution_id;
          oldEvent.read_status = newEvent.read_status ?? oldEvent.read_status;

          // Check if event has been opened in tab, if it has, don't replace data
          if (!state.openedEventsTab.includes(newEvent.id)) {
            oldEvent.data = newEvent.data;
          }
        });
        const eventsToAdd = events.filter(
          (incomingEvent) => !eventIDs.includes(incomingEvent.id)
        );
        // If there are new events and we already started monitoring, open floating tray
        if (eventsToAdd.length > 0) {
          // Checks to see if only a single pause_notification event is being added
          const isPauseEvent =
            eventsToAdd.length === 1 &&
            eventsToAdd[0].event_type === EventTypes.pause_notification;
          state.communityEvents = state.communityEvents.concat(eventsToAdd);
          // If it's just a pause_notification_getting added, addedEvent will not
          // be set to true
          if (state.startMonitoringNewEvents && !isPauseEvent)
            state.addedEvent = true;
        }
      }
      state.communityEvents.sort((a, b) =>
        sortAlphabetical(b.time_created, a.time_created)
      );
      state.firebaseState = ReducerStates.SUCCEEDED;
    },
    setResolvedEvents(state, action) {
      const events: IEvent[] = action.payload;
      state.resolvedEvents = events;
      state.resolvedEvents.sort((a, b) =>
        sortAlphabetical(b.time_created, a.time_created)
      );
      state.firebaseState = ReducerStates.SUCCEEDED;
    },
    startLoadingDashboard(state) {
      state.firebaseState = ReducerStates.PENDING;
    },
    failedFirebaseDashboard(state) {
      state.firebaseState = ReducerStates.FAILED;
    },
    updateEventDetails(state, action: PayloadAction<IEvent>) {
      const incomingEvent: IEvent = action.payload;
      if (state.communityEvents === undefined) return;
      const eventIndex = state.communityEvents.findIndex((event) => {
        return event.id === incomingEvent.id;
      });
      if (eventIndex !== -1) {
        state.communityEvents[eventIndex].status = incomingEvent.status;
        state.communityEvents[eventIndex].responder_id =
          incomingEvent.responder_id;

        if (state.communityEvents[eventIndex].time_assigned !== undefined)
          state.communityEvents[eventIndex].time_assigned =
            incomingEvent.time_assigned;
        if (state.communityEvents[eventIndex].time_resolved !== undefined)
          state.communityEvents[eventIndex].time_resolved =
            incomingEvent.time_resolved;
        if (state.communityEvents[eventIndex].event_resolution_id !== undefined)
          state.communityEvents[eventIndex].event_resolution_id =
            incomingEvent.event_resolution_id;
      }
    },
    updateEventNotes(
      state,
      action: PayloadAction<{ eventID: string; eventNotes: IEventNotes[] }>
    ) {
      const eventID = action.payload.eventID;
      const eventNotes: IEventNotes[] = action.payload.eventNotes;
      if (state.communityEvents === undefined) return;
      const eventIndex = state.communityEvents.findIndex(
        (event) => event.id === eventID
      );
      // If event exists
      if (eventIndex !== -1) {
        // If event doesn't have notes, replace them directly
        // Notes should be sorted in decending order by timestamp
        const notes = state.communityEvents[
          eventIndex
        ].event_notes?.sort((a, b) =>
          sortAlphabetical(b.timestamp, a.timestamp)
        );
        if (notes === undefined) {
          state.communityEvents[eventIndex].event_notes = eventNotes;
        } else {
          // Search for new notes and add them at the beggining
          eventNotes.forEach((note) => {
            const noteIndex = notes.findIndex(
              (localNote) => localNote.id === note.id
            );
            if (noteIndex === -1) {
              notes.unshift(note);
            }
          });
        }
      }
    },
    removeCommunityEvent(state, action: PayloadAction<string>) {
      if (state.communityEvents === undefined) return;
      const eventID = action.payload;
      const isOpened = state.tabs?.find((tab) => tab.id === eventID);
      // If tab is not open, then delete event
      if (isOpened === undefined) {
        const localEventIndex = state.communityEvents.findIndex(
          (event) => event.id === eventID
        );
        if (localEventIndex !== -1) {
          if (isHardEvent(state.communityEvents[localEventIndex])) {
            state.communityEvents[localEventIndex].status =
              EventStatus.RESOLVED;
          } else {
            state.communityEvents[localEventIndex].read_status =
              ReadStatus.READ;
          }
        }
      }
    },
    updateUnit(state, action: PayloadAction<IUnit>) {
      if (state.units) {
        const unit = action.payload;
        const unitIndex = state.units.findIndex(
          (localUnit) => localUnit.id === unit.id
        );
        if (unitIndex !== -1) {
          state.units[unitIndex] = { ...state.units[unitIndex], ...unit };
        }
        state.units = sortUnits(state.units);
      } else {
        const unit = action.payload;
        if (unit && unit.community_id) {
          const unitIndex = state.orgUnits[unit.community_id].findIndex(
            (localUnit) => localUnit.id === unit.id
          );
          if (unitIndex !== -1) {
            state.orgUnits[unit.community_id][unitIndex] = {
              ...state.orgUnits[unit.community_id][unitIndex],
              ...unit,
            };
          }
        }
      }
    },
    startMonitoringNewEvents(state) {
      state.startMonitoringNewEvents = true;
    },
    consumeAddedEvent(state) {
      state.addedEvent = false;
    },
    setCommunityFilter(state, action) {
      state.communityFilter = action.payload;
      localStorage.setItem(StorageKeys.COMMUNITY_FILTER, action.payload);
    },
    setIsCommunityFiltered(state, action) {
      state.isCommunityFiltered = action.payload;
      localStorage.setItem(StorageKeys.IS_COMMUNITY_FILTERED, action.payload);
    },
    setIsStatusFiltered(state, action) {
      state.isStatusFiltered = action.payload;
      localStorage.setItem(StorageKeys.IS_STATUS_FILTERED, action.payload);
    },
    setIsUserFiltered(state, action) {
      state.isUserFiltered = action.payload;
      localStorage.setItem(StorageKeys.IS_USER_FILTERED, action.payload);
    },
    setStatusFilter(state, action) {
      state.statusFilter = action.payload;
      localStorage.setItem(StorageKeys.STATUS_FILTER, action.payload);
    },
    setUserFilter(state, action) {
      state.userFilter = action.payload;
      localStorage.setItem(StorageKeys.USER_FILTER, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommunityData.pending, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialLDashboardState.errorCode;
        state.errorMessage = initialLDashboardState.errorMessage;
      })
      .addCase(getCommunityData.fulfilled, (state, action) => {
        const units: IEnhancedUnit[] = action.payload;
        let tempUnits: IEnhancedUnit[] = [];
        // sort communities by unit number
        if (units && units.length > 0) {
          tempUnits = sortUnits(units);
        }
        state.units = tempUnits;
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(getCommunityData.rejected, (state, action) => {
        state.state = ReducerStates.FAILED;
        state.errorMessage = action.error.message;
      })
      .addCase(markAllWarningsRead.pending, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialLDashboardState.errorCode;
        state.errorMessage = initialLDashboardState.errorMessage;
      })
      .addCase(markAllWarningsRead.fulfilled, (state) => {
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(markAllWarningsRead.rejected, (state, action) => {
        state.state = ReducerStates.FAILED;
        state.errorMessage = action.error.message;
      })
      .addCase(getUnitStatus.pending, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialLDashboardState.errorCode;
        state.errorMessage = initialLDashboardState.errorMessage;
      })
      .addCase(getUnitStatus.fulfilled, (state, action) => {
        state.state = ReducerStates.SUCCEEDED;
        if (action.meta.arg) {
          const payload = action.payload.dashboard;
          payload.local_time_updated = DateTime.now().toISO();
          state.unitDashboard[action.meta.arg] = payload;
          state.unitEvents[action.meta.arg] = action.payload.events;
          state.unitActivity[action.meta.arg] = action.payload.activity;
        }
      })
      .addCase(getUnitStatus.rejected, (state, action) => {
        state.state = ReducerStates.FAILED;
        state.errorMessage = action.error.message;
      })
      .addCase(postNewComment.pending, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialLDashboardState.errorCode;
        state.errorMessage = initialLDashboardState.errorMessage;
      })
      .addCase(postNewComment.fulfilled, (state) => {
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(postNewComment.rejected, (state, action) => {
        state.state = ReducerStates.FAILED;
        state.errorMessage = action.error.message;
      })
      .addCase(changeSelectedCommunity, (state) => {
        state.state = ReducerStates.IDLE;
        state.firebaseState = ReducerStates.IDLE;
        state.openTray = false;
        state.selectedTab = initialLDashboardState.selectedTab;
        state.tabs = initialLDashboardState.tabs;
        state.communityEvents = initialLDashboardState.communityEvents;
        state.resolvedEvents = initialLDashboardState.resolvedEvents;
        state.units = initialLDashboardState.units;
        state.allUnits = initialLDashboardState.allUnits;
        state.openedEventsTab = initialLDashboardState.openedEventsTab;
        state.unitDashboard = initialLDashboardState.unitDashboard;
        state.unitEvents = initialLDashboardState.unitEvents;
        state.startMonitoringNewEvents =
          initialLDashboardState.startMonitoringNewEvents;
        state.addedEvent = initialLDashboardState.addedEvent;
        state.eventData = initialLDashboardState.eventData;
      })
      .addCase(changeSelectedOrganization, (state) => {
        state.state = ReducerStates.IDLE;
        state.firebaseState = ReducerStates.IDLE;
        state.openTray = false;
        state.selectedTab = initialLDashboardState.selectedTab;
        state.tabs = initialLDashboardState.tabs;
        state.communityEvents = initialLDashboardState.communityEvents;
        state.resolvedEvents = initialLDashboardState.resolvedEvents;
        state.units = initialLDashboardState.units;
        state.allUnits = initialLDashboardState.allUnits;
        state.openedEventsTab = initialLDashboardState.openedEventsTab;
        state.unitDashboard = initialLDashboardState.unitDashboard;
        state.unitEvents = initialLDashboardState.unitEvents;
        state.startMonitoringNewEvents =
          initialLDashboardState.startMonitoringNewEvents;
        state.addedEvent = initialLDashboardState.addedEvent;
        state.eventData = initialLDashboardState.eventData;
      })
      .addCase(setAllNotifications, (state) => {
        state.state = ReducerStates.IDLE;
        state.firebaseState = ReducerStates.IDLE;
        state.openTray = false;
        state.selectedTab = initialLDashboardState.selectedTab;
        state.tabs = initialLDashboardState.tabs;
        state.communityEvents = initialLDashboardState.communityEvents;
        state.resolvedEvents = initialLDashboardState.resolvedEvents;
        state.units = initialLDashboardState.units;
        state.openedEventsTab = initialLDashboardState.openedEventsTab;
        state.unitDashboard = initialLDashboardState.unitDashboard;
        state.unitEvents = initialLDashboardState.unitEvents;
        state.startMonitoringNewEvents =
          initialLDashboardState.startMonitoringNewEvents;
        state.addedEvent = initialLDashboardState.addedEvent;
        state.eventData = initialLDashboardState.eventData;
      })
      .addCase(assignEventResponder.pending, (state, action) => {
        const fromDashboard = action.meta.arg.fromDashboard;

        state.updateNotifications = fromDashboard;
        if (fromDashboard) state.state = ReducerStates.PENDING;
        state.errorCode = initialLDashboardState.errorCode;
        state.errorMessage = initialLDashboardState.errorMessage;
      })
      .addCase(assignEventResponder.fulfilled, (state, action) => {
        const newEvent: IEvent = action.payload;
        const eventIndex = state.communityEvents?.findIndex(
          (event) => event.id === newEvent.id
        );
        if (
          state.communityEvents !== undefined &&
          eventIndex !== -1 &&
          eventIndex !== undefined
        ) {
          state.communityEvents[eventIndex] = newEvent;
        }

        if (state.updateNotifications) state.state = ReducerStates.SUCCEEDED;
        state.updateNotifications = initialLDashboardState.updateNotifications;
      })
      .addCase(assignEventResponder.rejected, (state, action) => {
        if (state.updateNotifications) {
          state.state = ReducerStates.FAILED;
          state.errorMessage = action.error.message;
          state.updateNotifications =
            initialLDashboardState.updateNotifications;
        }
      })
      .addCase(resolveEventWithPin.pending, (state, action) => {
        const fromDashboard = action.meta.arg.fromDashboard;
        state.updateNotifications = fromDashboard;
        if (fromDashboard) state.state = ReducerStates.PENDING;
        state.errorCode = initialLDashboardState.errorCode;
        state.errorMessage = initialLDashboardState.errorMessage;
      })
      .addCase(resolveEventWithPin.fulfilled, (state, action) => {
        const newEvent: IEvent = action.payload;
        const communityEvents = state.communityEvents;
        if (communityEvents) {
          const eventIndex = communityEvents.findIndex(
            (eventEl) => eventEl.id === newEvent.id
          );
          if (eventIndex >= 0) {
            communityEvents[eventIndex] = newEvent;
          } else {
            communityEvents.push(newEvent);
          }
        }
        if (state.updateNotifications) state.state = ReducerStates.SUCCEEDED;
        state.updateNotifications = initialLDashboardState.updateNotifications;
      })
      .addCase(resolveEventWithPin.rejected, (state, action) => {
        if (state.updateNotifications) {
          state.state = ReducerStates.FAILED;
          state.errorMessage = action.error.message;
          state.updateNotifications =
            initialLDashboardState.updateNotifications;
        }
      })
      .addCase(toggleUnitPaused.pending, (state, action) => {
        const fromDashboard = action.meta.arg.fromDashboard;
        if (fromDashboard) {
          state.updateNotifications = fromDashboard;
          state.state = ReducerStates.PENDING;
          state.errorCode = initialLDashboardState.errorCode;
          state.errorMessage = initialLDashboardState.errorMessage;
        }
      })
      .addCase(toggleUnitPaused.fulfilled, (state, action) => {
        if (action.payload) {
          const { unit, event } = action.payload;
          const unitIndex = state.units?.findIndex(
            (current) => current.id === unit.id
          );
          if (
            state.units !== undefined &&
            unitIndex !== -1 &&
            unitIndex !== undefined
          ) {
            state.units[unitIndex].is_notifications_paused =
              unit.is_notifications_paused;
            state.units[unitIndex].pause_notifications_expiration =
              unit.pause_notifications_expiration;
            state.units[unitIndex].security_notification_enabled =
              unit.security_notification_enabled;
          }
          const eventIndex = state.communityEvents?.findIndex(
            (current) => current.id === event.id
          );
          if (
            state.communityEvents !== undefined &&
            eventIndex !== -1 &&
            eventIndex !== undefined
          ) {
            state.communityEvents[eventIndex] = event;
          }
        }

        if (state.updateNotifications) state.state = ReducerStates.SUCCEEDED;

        state.updateNotifications = initialLDashboardState.updateNotifications;
      })
      .addCase(toggleUnitPaused.rejected, (state, action) => {
        if (state.updateNotifications) {
          state.state = ReducerStates.FAILED;
          state.errorMessage = action.error.message;
          state.updateNotifications =
            initialLDashboardState.updateNotifications;
        }
      })
      .addCase(resolveEventWithPassword.pending, (state, action) => {
        const fromDashboard = action.meta.arg.fromDashboard;
        state.updateNotifications = fromDashboard;
        if (fromDashboard) state.state = ReducerStates.PENDING;
        state.errorCode = initialLDashboardState.errorCode;
        state.errorMessage = initialLDashboardState.errorMessage;
      })
      .addCase(resolveEventWithPassword.fulfilled, (state, action) => {
        const newEvent: IEvent = action.payload;
        const communityEvents = state.communityEvents;
        if (communityEvents) {
          const eventIndex = communityEvents.findIndex(
            (eventEl) => eventEl.id === newEvent.id
          );
          if (eventIndex >= 0) {
            communityEvents[eventIndex] = newEvent;
          } else {
            communityEvents.push(newEvent);
          }
        }
        if (state.updateNotifications) state.state = ReducerStates.SUCCEEDED;
        state.updateNotifications = initialLDashboardState.updateNotifications;
      })
      .addCase(resolveEventWithPassword.rejected, (state, action) => {
        if (state.updateNotifications) {
          state.state = ReducerStates.FAILED;
          state.errorMessage = action.error.message;
          state.updateNotifications =
            initialLDashboardState.updateNotifications;
        }
      })
      .addCase(toggleUnitWatchFlag.pending, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialLDashboardState.errorCode;
        state.errorMessage = initialLDashboardState.errorMessage;
      })
      .addCase(toggleUnitWatchFlag.fulfilled, (state, action) => {
        const newUnit: IUnit = action.payload;
        const unitIndex = state.units?.findIndex(
          (unit) => unit.id === newUnit.id
        );
        if (
          state.units !== undefined &&
          unitIndex !== -1 &&
          unitIndex !== undefined
        ) {
          state.units[unitIndex].is_watched = newUnit.is_watched;
        }
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(toggleUnitWatchFlag.rejected, (state, action) => {
        state.state = ReducerStates.FAILED;
        state.errorMessage = action.error.message;
      })
      .addCase(getEventDataDashboard.pending, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialLDashboardState.errorCode;
        state.errorMessage = initialLDashboardState.errorMessage;
      })
      .addCase(getEventDataDashboard.fulfilled, (state, action) => {
        const event: IEvent = action.payload;
        if (state.communityEvents !== undefined) {
          const localEventIndex = state.communityEvents.findIndex(
            (_event) => _event.id === event.id
          );
          if (localEventIndex !== -1) {
            state.communityEvents[localEventIndex] = {
              ...state.communityEvents[localEventIndex],
              ...event,
            };
            state.openedEventsTab.push(event.id);
          } else {
            state.openedEventsTab.push(event.id);
            state.communityEvents.push(event);
          }
          if (state.eventData[event.id] !== undefined) {
            state.eventData[event.id].apiFailed = false;
          } else {
            state.eventData[event.id] = { apiFailed: false };
          }
        } else {
          state.communityEvents = [event];
        }
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(getEventDataDashboard.rejected, (state, action) => {
        state.state = ReducerStates.FAILED;
        state.errorMessage = action.error.message;
        if (state.eventData[action.meta.arg] !== undefined) {
          state.eventData[action.meta.arg].apiFailed = true;
        } else {
          state.eventData[action.meta.arg] = { apiFailed: true };
        }
      })
      .addCase(updateResident.fulfilled, (state, action) => {
        const unit = state.units?.find(
          (unit) => unit.id === action.payload.unit_id
        );
        if (unit) {
          if (unit.residents && unit.residents.length > 0) {
            unit.residents[0] = { ...unit.residents[0], ...action.payload };
          } else {
            unit.residents = [action.payload];
          }
        }
      })
      .addCase(addResident.fulfilled, (state, action) => {
        if (state.units && action.payload) {
          const unitToUpdate = state.units.find(
            (unit) => unit.id === action.payload.unit_id
          );
          if (unitToUpdate) {
            unitToUpdate.residents = [action.payload];
          }
        }
      })
      .addCase(loadOrganizationData.fulfilled, (state, action) => {
        state.orgUnits = action.payload.orgUnits;
      })
      .addCase(loadAllUnitUserData.fulfilled, (state, action) => {
        state.allUnits = action.payload.allUnits;
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(loadAllUnitUserData.pending, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialLDashboardState.errorCode;
        state.errorMessage = initialLDashboardState.errorMessage;
      })
      .addCase(loadAllUnitUserData.rejected, (state, action) => {
        state.state = ReducerStates.FAILED;
        state.errorMessage = action.error.message;
      })
      .addCase(logout.type, (state) => {
        return initialLDashboardState;
      })
      .addCase(createUnit.fulfilled, (state, action) => {
        if (action.payload && state.units) {
          const newUnit = action.payload.newUnit;
          if (
            action.meta.arg.unit_type === UnitTypes.HELP_AT_HOME &&
            action.payload.newResident
          ) {
            newUnit.residents = [action.payload.newResident];
          }
          const newUnits: IUnit[] = [...state.units, action.payload.newUnit];
          state.units = sortUnits(newUnits);
        }
      })
      .addCase(deleteCommunityUnit.fulfilled, (state, action) => {
        if (action.payload) {
          const deletedUnitIndex = state.units?.findIndex(
            (unit) => unit.id === action.meta.arg
          );
          if (
            deletedUnitIndex !== undefined &&
            deletedUnitIndex !== -1 &&
            state.units
          ) {
            state.units.splice(deletedUnitIndex, 1);
            state.units = sortUnits(state.units);
          }
        }
      })
      .addCase(deleteResident.fulfilled, (state, action) => {
        if (state.units) {
          const residentID = action.meta.arg.resident_id;
          state.units.forEach((unit) => {
            if (unit.residents) {
              unit.residents = unit.residents.filter(
                (resident) => resident.id !== residentID
              );
            }
          });
        }
      });
  },
});

const { reducer } = dashboardSlice;
export const {
  clearUnitStatus,
  clearAllUnitStatus,
  addTab,
  closeTab,
  changeFocus,
  minimizeTabTray,
  maximizeTabTray,
  closeTabTray,
  setCommunityEvents,
  startLoadingDashboard,
  updateEventDetails,
  updateEventNotes,
  removeCommunityEvent,
  updateUnit,
  failedFirebaseDashboard,
  startMonitoringNewEvents,
  consumeAddedEvent,
  setCommunityFilter,
  setStatusFilter,
  setUserFilter,
  setIsCommunityFiltered,
  setIsStatusFiltered,
  setIsUserFiltered,
  setResolvedEvents,
} = dashboardSlice.actions;

export default reducer;
