import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  ICommunityGroup,
  ICommunityUserDetails,
  IEventResolution,
  IResidentPhoneNumber,
  IRoleTypes,
} from "../../services/header.services";
import {
  CommunityTypes,
  DashboardViewTypes,
  ReducerStates,
  RoleTypes,
  sortAlphabetical,
  UnitTypes,
} from "../../helpers/constants";
import { ICommunities, IUserDetails } from "../../services/header.services";
import { finishAccountCreation, logout } from "../login/loginSlice";
import { StorageKeys } from "../../services/constants";
import { getDropdownList } from "./CommunityDropdown";
import { IUnit } from "../../services/dashboard.services";
import {
  deleteCommunityUser,
  submitCommunityInfoChanges,
  submitMyProfileChanges,
  toggleUnitPaused,
  updateCommunityUser,
  updateNotificationGroup,
  sendHandOff,
  addNotificationGroup,
  deleteNotificationGroup,
} from "../app/asyncThunkActions";
import { sortUnits } from "../dashboard/dashboardSlice";
import {
  getCommunityUnits,
  getHeaderInformation,
  loadAllUnitUserData,
  loadCommunityData,
  loadCommunityGroups,
  loadCommunityUsers,
  loadOrganizationData,
  updateCommunityBadges,
} from "./headerThunks";
import { IFailedRequest } from "../../services/login.services";
import {
  createPhoneNumber,
  createUnit,
  deletePhoneNumber,
  getResidentInfo,
  updatePhoneNumber,
  updateResident,
  deleteCommunityUnit,
  updateCommunityUnit,
  addResident,
  toggleSettingsPauseUnit,
  setUnitPauseExpiration,
  setSecurityNotificationPause,
  deleteResident,
} from "../settings/settingsThunks";
import i18n, { LookUpQueryString } from "../../i18n";

interface ICommunityData {
  users: IUserDetails[];
  eventResolutions: IEventResolution[];
}
export interface IOrganizationData {
  [key: string]: ICommunityData;
}

const updateUnitInPauseNotification = <T extends IUnit>(
  oldArr: T[],
  newItem: T
) => {
  const oldItemIndex = oldArr.findIndex((item) => item.id === newItem.id);
  if (oldItemIndex !== undefined && oldItemIndex !== -1) {
    oldArr[oldItemIndex] = {
      ...oldArr[oldItemIndex],
      is_notifications_paused: newItem.is_notifications_paused,
      pause_notifications_expiration: newItem.pause_notifications_expiration,
      security_notification_enabled: newItem.security_notification_enabled,
    };
    return oldArr;
  }
  return oldArr;
};

export interface IHeaderState {
  communities?: ICommunities[];
  selectedCommunity?: ICommunities;
  selectedOrganization?: string;
  user?: IUserDetails;
  users?: IUserDetails[];
  eventResolutions?: IEventResolution[];
  organizationData?: IOrganizationData;
  communityUserData?: ICommunityUserDetails;
  units?: IUnit[];
  userRoles?: IRoleTypes[];
  communityGroups?: ICommunityGroup[];
  validUserRole: boolean;
  installerRole: boolean;
  firebaseState: ReducerStates;
  state: ReducerStates;
  errorCode: string;
  errorMessage?: string;
  showTimezones?: boolean;
  showBanner: boolean;
  allNotifications?: boolean;
}

export const initialHeaderState: IHeaderState = {
  communities: undefined,
  selectedCommunity: undefined,
  selectedOrganization: undefined,
  user: undefined,
  users: undefined,
  eventResolutions: undefined,
  organizationData: undefined,
  communityUserData: undefined,
  units: undefined,
  userRoles: undefined,
  communityGroups: undefined,
  validUserRole: false,
  installerRole: false,
  firebaseState: ReducerStates.IDLE,
  state: ReducerStates.IDLE,
  errorCode: "",
  errorMessage: undefined,
  showBanner: false,
  allNotifications: false,
};

const headerAsyncThunks = [
  getHeaderInformation,
  loadCommunityData,
  loadOrganizationData,
  loadCommunityGroups,
  loadCommunityUsers,
  getCommunityUnits,
  toggleSettingsPauseUnit,
  setUnitPauseExpiration,
  setSecurityNotificationPause,
] as const;

// Check the state for every async thunk
const isAPendingAction = isPending(...headerAsyncThunks);

const isAFulfilledAction = isFulfilled(...headerAsyncThunks);

const isARejectedAction = isRejected(...headerAsyncThunks);

isFulfilled(
  setSecurityNotificationPause,
  setUnitPauseExpiration,
  toggleSettingsPauseUnit
);

const validRoles = (role: string | undefined) => {
  if (role === "" || role === undefined) return false;
  return (
    role === RoleTypes.admin ||
    role === RoleTypes.caregiver ||
    role === RoleTypes.org ||
    installerRole(role)
  );
};

const installerRole = (role: string | undefined) => {
  if (role === "" || role === undefined) return false;
  return role === RoleTypes.installer;
};

const showTimezones = (units: IUnit[], timeZone: string | undefined) => {
  let unitTimezones = new Set<string>();
  if (timeZone) {
    unitTimezones.add(timeZone);
  }
  units.forEach((unit) => {
    if (unit.time_zone != null) {
      unitTimezones.add(unit.time_zone);
    }
  });
  return unitTimezones.size > 1;
};

const headerSlice = createSlice({
  name: "header",
  initialState: initialHeaderState,
  reducers: {
    changeSelectedCommunity(state, action) {
      state.selectedOrganization = initialHeaderState.selectedOrganization;
      state.organizationData = initialHeaderState.organizationData;
      state.units = initialHeaderState.units;
      state.selectedCommunity = action.payload;
      state.users = initialHeaderState.users;
      state.communityGroups = initialHeaderState.communityGroups;
      state.allNotifications = initialHeaderState.allNotifications;
      if (state.selectedCommunity !== undefined) {
        localStorage.setItem(
          StorageKeys.SELECTED_COMMUNITY_ID,
          state.selectedCommunity.id
        );
      }
      localStorage.removeItem(StorageKeys.SELECTED_ORGANIZATION_ID);
      localStorage.removeItem(StorageKeys.ALL_NOTIFICATIONS);
      state.validUserRole = validRoles(state.selectedCommunity?.role[0]);
      state.installerRole = installerRole(state.selectedCommunity?.role[0]);
    },
    changeSelectedOrganization(state, action) {
      state.selectedOrganization = action.payload;
      state.selectedCommunity = initialHeaderState.selectedCommunity;
      state.users = initialHeaderState.users;
      state.eventResolutions = initialHeaderState.eventResolutions;
      state.units = initialHeaderState.units;
      state.users = initialHeaderState.users;
      state.communityGroups = initialHeaderState.communityGroups;
      state.allNotifications = initialHeaderState.allNotifications;
      if (state.selectedOrganization !== undefined) {
        localStorage.setItem(
          StorageKeys.SELECTED_ORGANIZATION_ID,
          state.selectedOrganization
        );
      }
      localStorage.removeItem(StorageKeys.SELECTED_COMMUNITY_ID);
      localStorage.removeItem(StorageKeys.ALL_NOTIFICATIONS);
      state.validUserRole = true;
    },
    setAllNotifications: (state, action) => {
      state.allNotifications = action.payload;
      localStorage.setItem(StorageKeys.ALL_NOTIFICATIONS, action.payload);
      state.selectedOrganization = initialHeaderState.selectedOrganization;
      state.organizationData = initialHeaderState.organizationData;
      state.units = initialHeaderState.units;
      state.selectedCommunity = initialHeaderState.selectedCommunity;
      state.users = initialHeaderState.users;
      state.communityGroups = initialHeaderState.communityGroups;
      localStorage.removeItem(StorageKeys.SELECTED_COMMUNITY_ID);
      localStorage.removeItem(StorageKeys.SELECTED_ORGANIZATION_ID);
    },
    updateBannerState(state, action) {
      state.showBanner = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHeaderInformation.fulfilled, (state, action) => {
        const communities: ICommunities[] = action.payload.communities;
        let tempCommunities: ICommunities[] = [];
        if (communities && communities.length > 0) {
          // find pro communities
          tempCommunities = communities
            .filter(
              (community) => community.community_type === CommunityTypes.pro
            )
            // Filter comms for which I'm not admin or caregiver
            .filter((community) =>
              validRoles(
                community.role.length > 0 ? community.role[0] : undefined
              )
            );
          // sort communities by name
          tempCommunities = tempCommunities.sort((a, b) =>
            sortAlphabetical(a.name, b.name)
          );
        }
        // set selected community
        state.user = action.payload.userDetails;
        state.communities = tempCommunities;

        if (state.communities.length > 0) {
          const orderedList = getDropdownList(state.communities);

          const savedSelectedCommunityID = localStorage.getItem(
            StorageKeys.SELECTED_COMMUNITY_ID
          );
          const savedSelectedOrganizationID = localStorage.getItem(
            StorageKeys.SELECTED_ORGANIZATION_ID
          );
          const savedAllNotifications = localStorage.getItem(
            StorageKeys.ALL_NOTIFICATIONS
          );
          state.allNotifications = savedAllNotifications === "true";

          // Check if a previous selected organization exists
          if (savedSelectedOrganizationID !== null && !state.allNotifications) {
            const selectedOrganization = orderedList.find(
              (item) => item.id === savedSelectedOrganizationID
            );
            if (selectedOrganization !== undefined) {
              localStorage.setItem(
                StorageKeys.SELECTED_ORGANIZATION_ID,
                selectedOrganization.id
              );
              state.selectedOrganization = selectedOrganization.id;
            } else {
              localStorage.removeItem(StorageKeys.SELECTED_ORGANIZATION_ID);
            }
          } // If exists, make it the default one, if not select the first
          if (savedSelectedCommunityID !== null && !state.allNotifications) {
            const selectedCommunity = tempCommunities.find(
              (community) => community.id === savedSelectedCommunityID
            );
            if (selectedCommunity !== undefined) {
              localStorage.setItem(
                StorageKeys.SELECTED_COMMUNITY_ID,
                selectedCommunity.id
              );
              state.selectedCommunity = selectedCommunity;
            } else {
              localStorage.removeItem(StorageKeys.SELECTED_COMMUNITY_ID);
            }
          }

          if (
            state.selectedCommunity === undefined &&
            state.selectedOrganization === undefined &&
            orderedList.length > 0 &&
            !state.allNotifications
          ) {
            // If there are not saved selected items, choose the first one of the list
            const firstElement = orderedList[0];
            if (firstElement.type === DashboardViewTypes.ORGANIZATION) {
              state.selectedOrganization = firstElement.id;
              localStorage.setItem(
                StorageKeys.SELECTED_ORGANIZATION_ID,
                state.selectedOrganization
              );
            }
            if (firstElement.type === DashboardViewTypes.COMMUNITY) {
              const selectedCommunity = tempCommunities.find(
                (community) => community.id === firstElement.id
              );
              if (selectedCommunity !== undefined) {
                localStorage.setItem(
                  StorageKeys.SELECTED_COMMUNITY_ID,
                  selectedCommunity.id
                );
                state.selectedCommunity = selectedCommunity;
              }
            }
          }
        } else {
          localStorage.removeItem(StorageKeys.SELECTED_COMMUNITY_ID);
          localStorage.removeItem(StorageKeys.SELECTED_ORGANIZATION_ID);
          localStorage.removeItem(StorageKeys.ALL_NOTIFICATIONS);
        }

        if (state.communities && state.communities.length > 0) {
          const validRole = state.communities.every((community) => {
            return community.role && community.role.some(validRoles);
          });
          state.validUserRole = validRole;
        } else {
          state.validUserRole = validRoles(state.selectedCommunity?.role[0]);
        }

        state.installerRole = installerRole(state.selectedCommunity?.role[0]);
        state.userRoles = action.payload.userRoles;
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(logout.type, (state) => {
        return initialHeaderState;
      })
      .addCase(loadCommunityData.fulfilled, (state, action) => {
        state.state = ReducerStates.SUCCEEDED;
        state.users = action.payload.users;
        state.eventResolutions = action.payload.eventResolutions;
        state.units = sortUnits(action.payload.units);
        state.validUserRole = validRoles(state.selectedCommunity?.role[0]);
        state.showTimezones = showTimezones(
          state.units,
          state.selectedCommunity?.time_zone
        );
      })
      .addCase(loadOrganizationData.fulfilled, (state, action) => {
        state.state = ReducerStates.SUCCEEDED;
        state.organizationData = action.payload.organizationData;
        state.validUserRole = true;
      })
      .addCase(loadAllUnitUserData.fulfilled, (state, action) => {
        state.state = ReducerStates.SUCCEEDED;
        state.communityUserData = action.payload.communityUsers;
        state.eventResolutions = action.payload.eventResolutions;
      })
      .addCase(loadAllUnitUserData.pending, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialHeaderState.errorCode;
        state.errorMessage = initialHeaderState.errorMessage;
      })
      .addCase(loadAllUnitUserData.rejected, (state, action) => {
        state.state = ReducerStates.FAILED;
        state.errorMessage = action.error.message;
      })
      .addCase(updateCommunityBadges.fulfilled, (state, action) => {
        const communities: ICommunities[] = action.payload;
        if (!Array.isArray(communities)) return;
        communities?.forEach((community) => {
          if (state.communities === undefined) return;
          const localCommunityIndex = state.communities.findIndex(
            (_community) => _community.id === community.id
          );
          if (localCommunityIndex !== -1) {
            state.communities[localCommunityIndex].unread_events =
              community.unread_events;
            state.communities[localCommunityIndex].help_event =
              community.help_event;
            if (
              JSON.stringify(state.communities[localCommunityIndex].role) !==
              JSON.stringify(community.role)
            ) {
              const hasValidRole = community.role.some((role) =>
                validRoles(role)
              );
              if (hasValidRole) {
                state.communities[localCommunityIndex].role = community.role;
              } else {
                state.communities?.splice(localCommunityIndex, 1);
              }
            }
          }
        });
      })
      .addCase(finishAccountCreation, (state, action) => {
        if (action.payload !== undefined) {
          localStorage.setItem(
            StorageKeys.SELECTED_COMMUNITY_ID,
            action.payload
          );
        }
      })
      .addCase(toggleUnitPaused.pending, (state, action) => {
        if (action.meta.arg.fromNotifications) {
          state.state = ReducerStates.PENDING;
        }
      })
      .addCase(toggleUnitPaused.fulfilled, (state, action) => {
        if (action.payload) {
          const { unit } = action.payload;
          const unitIndex = state.units?.findIndex(
            (current) => current.id === unit.id
          );
          if (
            state.units !== undefined &&
            unitIndex !== -1 &&
            unitIndex !== undefined
          ) {
            state.units[unitIndex].is_notifications_paused =
              unit.is_notifications_paused;
            state.units[unitIndex].pause_notifications_expiration =
              unit.pause_notifications_expiration;
            state.units[unitIndex].security_notification_enabled =
              unit.security_notification_enabled;
          }
        }
        if (action.meta.arg.fromNotifications)
          state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(toggleUnitPaused.rejected, (state, action) => {
        if (action.meta.arg.fromNotifications) {
          state.state = ReducerStates.FAILED;
          state.errorMessage = action.error.message;
        }
      })
      .addCase(submitMyProfileChanges.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload };
        if (
          state.user &&
          state.user.language !== null &&
          i18n.language !== state.user.language
        ) {
          localStorage.setItem(LookUpQueryString, state.user.language);
        }

        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(submitCommunityInfoChanges.fulfilled, (state, action) => {
        const oldCommunityTimezone = state.selectedCommunity?.time_zone;
        const updatedCommunity = action.payload;
        state.selectedCommunity = {
          ...state.selectedCommunity,
          ...updatedCommunity,
        };
        if (state.communities !== undefined) {
          let oldCommunityIndex = state.communities.findIndex(
            (community) => community.id === updatedCommunity.id
          );
          if (oldCommunityIndex !== -1) {
            state.communities[oldCommunityIndex] = {
              ...state.communities[oldCommunityIndex],
              ...updatedCommunity,
            };
          }
        }
        if (oldCommunityTimezone !== undefined && state.units !== undefined) {
          let unitTimezones = new Set<string>();
          unitTimezones.add(updatedCommunity.time_zone);
          state.units.forEach((unit) => {
            if (unit.time_zone === oldCommunityTimezone) {
              unit.time_zone = updatedCommunity.time_zone;
            }
            unitTimezones.add(unit.time_zone);
          });
          state.showTimezones = unitTimezones.size > 1;
        } else {
          state.showTimezones = false;
        }
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(loadCommunityGroups.fulfilled, (state, action) => {
        state.communityGroups = action.payload;
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(updateCommunityUser.fulfilled, (state, action) => {
        if (action.payload === undefined) return;

        if (action.payload.role !== undefined) {
          const rolePayload = action.payload.role;
          const updatedUser = state.users?.find(
            (_user) => _user.id === rolePayload.user_id
          );
          if (updatedUser) {
            updatedUser.role.name = rolePayload.name;
            updatedUser.role.all_units = rolePayload.all_units;
            updatedUser.units =
              state.units?.filter((unit) =>
                rolePayload.unit_ids.includes(unit.id)
              ) ?? [];
          }
        }
        if (action.payload.user !== undefined) {
          const userPayload = action.payload.user;
          const updatedUser = state.users?.find(
            (_user) => _user.id === userPayload?.id
          );
          if (updatedUser && userPayload && state.communityGroups) {
            if (userPayload.group_ids === undefined) {
              updatedUser.groups = [];
            } else {
              const communityGroups = JSON.parse(
                JSON.stringify(state.communityGroups)
              ) as ICommunityGroup[];
              updatedUser.groups = communityGroups
                .filter((comm) => userPayload.group_ids.includes(comm.id))
                .map((comm) => ({ id: comm.id, name: comm.name ?? "" }));
            }
          }
        }
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(deleteCommunityUser.fulfilled, (state, action) => {
        const userEmail = action.meta.arg.userEmail;
        const communityID = action.meta.arg.community_id;
        const deletedUserIndex = state.users?.findIndex(
          (user) => user.email === userEmail
        );
        const communityIndex = state.communities?.findIndex(
          (community) => community.id === communityID
        );

        // If user found, delete it from the list
        if (deletedUserIndex !== undefined && deletedUserIndex !== -1) {
          state.users?.splice(deletedUserIndex, 1);
        }
        if (userEmail === state.user?.email) {
          // If community found, delete it from the list
          if (communityIndex !== undefined && communityIndex !== -1) {
            state.communities?.splice(communityIndex, 1);
          }

          if (
            state.selectedCommunity !== undefined &&
            state.selectedCommunity.id === communityID
          ) {
            state.selectedCommunity = initialHeaderState.selectedCommunity;
          }
        }
      })
      .addCase(loadCommunityUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(
        updateNotificationGroup.fulfilled,
        (state, action: PayloadAction<ICommunityGroup>) => {
          if (action.payload === undefined) return;

          const groupIndex = state.communityGroups?.findIndex(
            (group) => group.id === action.payload.id
          );
          if (
            groupIndex !== undefined &&
            groupIndex !== -1 &&
            state.communityGroups !== undefined
          ) {
            state.communityGroups[groupIndex] = action.payload;
          }
        }
      )
      .addCase(
        addNotificationGroup.fulfilled,
        (state, action: PayloadAction<ICommunityGroup>) => {
          if (action.payload === undefined) return;

          if (state.communityGroups !== undefined) {
            state.communityGroups.push(action.payload);
          }
        }
      )
      .addCase(deleteNotificationGroup.fulfilled, (state, action) => {
        const groupID = action.meta.arg;
        const deletedGroupIndex = state.communityGroups?.findIndex(
          (group) => group.id === groupID
        );

        // If group found, delete it from the list
        if (deletedGroupIndex !== undefined && deletedGroupIndex !== -1) {
          state.communityGroups?.splice(deletedGroupIndex, 1);
        }
      })
      .addCase(sendHandOff.fulfilled, (state, action) => {
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(getCommunityUnits.fulfilled, (state, action) => {
        const units = state.units;
        if (units !== undefined) {
          const newUnits = action.payload;
          newUnits.forEach((newUnit) => {
            let oldUnitIndex = state.units?.findIndex(
              (oldUnit) => oldUnit.id === newUnit.id
            );
            if (oldUnitIndex !== undefined || oldUnitIndex === -1) {
              units[oldUnitIndex] = { ...units[oldUnitIndex], ...newUnit };
            } else {
              units.push(newUnit);
            }
          });
          state.units = sortUnits(units);
        } else {
          if (action.payload) {
            state.units = sortUnits(action.payload);
          }
        }
      })
      .addCase(createUnit.fulfilled, (state, action) => {
        if (action.payload && state.units) {
          const newUnit = action.payload.newUnit;
          if (
            action.meta.arg.unit_type === UnitTypes.HELP_AT_HOME &&
            action.payload.newResident
          ) {
            newUnit.residents = [action.payload.newResident];
          }
          const newUnits: IUnit[] = [...state.units, action.payload.newUnit];
          state.units = sortUnits(newUnits);
        }
      })
      .addCase(getResidentInfo.fulfilled, (state, action) => {
        const unit = state.units?.find(
          (unit) => unit.id === action.payload.unit_id
        );
        if (unit) {
          if (unit.residents && unit.residents.length > 0) {
            unit.residents[0] = action.payload;
          } else {
            unit.residents = [action.payload];
          }
        }
      })
      .addCase(updateResident.fulfilled, (state, action) => {
        const unit = state.units?.find(
          (unit) => unit.id === action.payload.unit_id
        );
        if (unit) {
          if (unit.residents && unit.residents.length > 0) {
            unit.residents[0] = { ...unit.residents[0], ...action.payload };
          } else {
            unit.residents = [action.payload];
          }
        }
      })
      .addCase(updatePhoneNumber.fulfilled, (state, action) => {
        const unit = state.units?.find(
          (unit) => unit.id === action.meta.arg.unit_id
        );
        if (unit) {
          // Update phone numbers of resident
          if (unit.residents && unit.residents.length > 0) {
            const oldPhoneNumbers = unit.residents[0].phone_numbers;
            if (oldPhoneNumbers) {
              const updatedPhoneIndex = oldPhoneNumbers.findIndex(
                (phone) => action.payload.id === phone.id
              );
              if (updatedPhoneIndex !== -1) {
                oldPhoneNumbers[
                  updatedPhoneIndex
                ] = action.payload as IResidentPhoneNumber;
              }
            } else {
              unit.residents[0].phone_numbers = [
                action.payload,
              ] as IResidentPhoneNumber[];
            }
          }
        }
      })
      .addCase(createPhoneNumber.fulfilled, (state, action) => {
        const unit = state.units?.find(
          (unit) => unit.id === action.meta.arg.unit_id
        );
        if (unit) {
          // Add phone numbers of resident
          if (unit.residents && unit.residents.length > 0) {
            const oldPhoneNumbers = unit.residents[0].phone_numbers;
            if (oldPhoneNumbers) {
              oldPhoneNumbers.push(action.payload as IResidentPhoneNumber);
            } else {
              unit.residents[0].phone_numbers = [
                action.payload,
              ] as IResidentPhoneNumber[];
            }
          }
        }
      })
      .addCase(deletePhoneNumber.fulfilled, (state, action) => {
        if (action.payload) {
          const unit = state.units?.find(
            (unit) => unit.id === action.meta.arg.unit_id
          );
          if (unit && unit.residents && unit.residents.length > 0) {
            const oldPhoneNumbers = unit.residents[0].phone_numbers;
            if (oldPhoneNumbers) {
              const deletedPhoneIndex = oldPhoneNumbers.findIndex(
                (phone) => phone.id === action.meta.arg.phoneNumberID
              );
              deletedPhoneIndex !== -1 &&
                oldPhoneNumbers.splice(deletedPhoneIndex, 1);
            }
          }
        }
      })
      .addCase(updateCommunityUnit.fulfilled, (state, action) => {
        const unitToUpdate = action.payload;
        if (unitToUpdate) {
          const oldUnitIndex = state.units?.findIndex(
            (unit) => unit.id === unitToUpdate.id
          );
          if (
            oldUnitIndex !== undefined &&
            oldUnitIndex !== -1 &&
            state.units
          ) {
            state.units[oldUnitIndex] = {
              ...state.units[oldUnitIndex],
              ...unitToUpdate,
            };
            state.units = sortUnits(state.units);
          }
        }
        if (state.units !== undefined) {
          state.showTimezones = showTimezones(
            state.units,
            state.selectedCommunity?.time_zone
          );
        } else {
          state.showTimezones = false;
        }
      })
      .addCase(deleteCommunityUnit.fulfilled, (state, action) => {
        if (action.payload) {
          const deletedUnitIndex = state.units?.findIndex(
            (unit) => unit.id === action.meta.arg
          );
          if (
            deletedUnitIndex !== undefined &&
            deletedUnitIndex !== -1 &&
            state.units
          ) {
            state.units.splice(deletedUnitIndex, 1);
            state.units = sortUnits(state.units);
          }
        }
      })
      .addCase(addResident.fulfilled, (state, action) => {
        if (state.units && action.payload) {
          const unitToUpdate = state.units.find(
            (unit) => unit.id === action.payload.unit_id
          );
          if (unitToUpdate) {
            unitToUpdate.residents = [action.payload];
          }
        }
      })
      .addCase(deleteResident.fulfilled, (state, action) => {
        if (state.units) {
          const residentID = action.meta.arg.resident_id;
          state.units.forEach((unit) => {
            if (unit.residents) {
              unit.residents = unit.residents.filter(
                (resident) => resident.id !== residentID
              );
            }
          });
        }
      })
      .addMatcher(
        isFulfilled(
          setSecurityNotificationPause,
          setUnitPauseExpiration,
          toggleSettingsPauseUnit
        ),
        (state, action) => {
          const unitToUpdate = action.payload;
          const units = state.units;
          if (unitToUpdate && units) {
            state.units = sortUnits(
              updateUnitInPauseNotification(units, unitToUpdate)
            );
          }
        }
      )
      .addMatcher(isAPendingAction, (state) => {
        state.errorMessage = initialHeaderState.errorMessage;
        state.state = ReducerStates.PENDING;
      })
      .addMatcher(isAFulfilledAction, (state) => {
        state.state = ReducerStates.SUCCEEDED;
      })
      .addMatcher(isARejectedAction, (state, action) => {
        if (action.meta.rejectedWithValue) {
          const error = (action.payload as unknown) as IFailedRequest;

          state.errorCode = error?.code || "";
          state.errorMessage = error?.message || "";
        } else {
          state.errorMessage = action.error.message;
        }
        state.state = ReducerStates.FAILED;
      });
  },
});

const { reducer } = headerSlice;

// Destructure and export the plain action creators
export const {
  changeSelectedCommunity,
  changeSelectedOrganization,
  updateBannerState,
  setAllNotifications,
} = headerSlice.actions;

export default reducer;
